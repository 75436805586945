import { createSlice } from '@reduxjs/toolkit';
import {
    getPropertyTypes,
    getPropertyTypesNotPaginated,
    getPropertyType,
    deletePropertyTypes,
    addPropertyTypes,
    updatePropertyTypes
} from './propertyTypeActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    propertyTypesLoading: false,
    propertyTypesSuccess: false,
    propertyTypeMsg: '',
    propertyTypes: []
};

const propertyType = createSlice({
    name: 'propertyType',
    initialState,
    reducers: {
        clearpropertyTypeMessages: (state) => {
            state.error = false;
            state.propertyTypeMsg = '';
            state.propertyTypesSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPropertyTypes.pending, (state) => {
            state.propertyTypesLoading = true;
        });
        builder.addCase(getPropertyTypes.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.propertyTypes = action.payload?.data.items;
            state.error = action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
        builder.addCase(getPropertyType.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.propertyType = action.payload?.data[0];
            state.error = action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
        builder.addCase(getPropertyTypesNotPaginated.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.propertyTypes = action.payload?.data;
            state.error = action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
        builder.addCase(addPropertyTypes.pending, (state) => {
            state.propertyTypesLoading = true;
        });
        builder.addCase(addPropertyTypes.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.error = action.payload?.error;
            state.propertyTypesSuccess = !action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
        builder.addCase(updatePropertyTypes.pending, (state) => {
            state.propertyTypesLoading = true;
        });
        builder.addCase(updatePropertyTypes.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.error = action.payload?.error;
            state.propertyTypesSuccess = !action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
        builder.addCase(deletePropertyTypes.fulfilled, (state, action) => {
            state.propertyTypesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.propertyTypes = action.payload?.data.items;
            state.error = action.payload?.error;
            state.propertyTypeMsg = action.payload?.msg;
        });
    }
});

export const { clearpropertyTypeMessages } = propertyType.actions;

export default propertyType.reducer;
