import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getMoveOuts = createAsyncThunk('MoveOut/getPaginatedMoveOut', async ({ reference_number }) => {
    try {
        let url = '';
        if (reference_number && page) {
            url = `/move-out/getMoveOut?ref_number=${reference_number}&page=${page}`;
        } else {
            url = `/move-out/getMoveOut?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getMoveOutNotPaginated = createAsyncThunk('MoveOut/getMoveOuts', async () => {
    try {
        const { data } = await api.get(`/move-out/getMoveOut`);
        return data;
    } catch (error) {}
});
export const getMoveOut = createAsyncThunk('MoveOut/getMoveOut', async ({ id }) => {
    try {
        const { data } = await api.get(`/move-out/getMoveOut?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteMoveOut = createAsyncThunk('MoveOut/deleteMoveOut', async ({ id }) => {
    try {
        const { data } = await api.delete(`/move-out/deleteMoveOut/${id}`);
        return data;
    } catch (error) {}
});

export const addMoveOut = createAsyncThunk('MoveOut/addMoveOut', async ({ move_out_date, move_out_reason }) => {
    try {
        const { data } = await api.post(`/move-out/addMoveOut`, {
            move_out_date,
            move_out_reason
        });
        return data;
    } catch (error) {}
});

export const updateMoveOut = createAsyncThunk(
    'MoveOut/updateMoveOut',
    async ({
        id,
        final_inspection_date,
        inspection_notes,
        keys_returned,
        outstanding_balance,
        deductions,
        deposit_refund_amount,
        deposit_refund_date,
        move_status,
        notes,
        tenant_id
    }) => {
        try {
            const { data } = await api.patch(`/move-out/editMoveOut/${id}`, {
                id,
                final_inspection_date,
                inspection_notes,
                keys_returned,
                outstanding_balance,
                deductions,
                deposit_refund_amount,
                deposit_refund_date,
                move_status,
                notes,
                tenant_id
            });
            return data;
        } catch (error) {}
    }
);
