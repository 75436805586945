import { createSlice } from '@reduxjs/toolkit';
import {
    addListing,
    deleteListing,
    getListing,
    getListings,
    getListingNotPaginated,
    fetchListingNotPaginated,
    updateListing,
    getAdminListing,
    controlListing
} from './listingsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    ListingsLoading: false,
    ListingsSuccess: false,
    ListingMsg: '',
    Listings: [],
    Listing: []
};

const Listing = createSlice({
    name: 'Listing',
    initialState,
    reducers: {
        clearListingMessages: (state) => {
            state.error = false;
            state.ListingMsg = '';
            state.ListingsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getListings.pending, (state) => {
            state.ListingsLoading = true;
        });
        builder.addCase(getListings.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Listings = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
            state.ListingsSuccess = !action.payload?.error;
        });
        builder.addCase(getListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.ListingsSuccess = !action.payload?.error;
            state.Listing = action.payload?.data[0];
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(getAdminListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.ListingsSuccess = !action.payload?.error;
            state.Listing = action.payload?.data[0];
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(getListingNotPaginated.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.ListingsSuccess = !action.payload?.error;
            state.Listings = action.payload?.data;
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(fetchListingNotPaginated.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.ListingsSuccess = !action.payload?.error;
            state.Listings = action.payload?.data;
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(addListing.pending, (state) => {
            state.ListingsLoading = true;
        });
        builder.addCase(addListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.error = action.payload?.error;
            state.ListingsSuccess = !action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(updateListing.pending, (state) => {
            state.ListingsLoading = true;
        });
        builder.addCase(updateListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.error = action.payload?.error;
            state.ListingsSuccess = !action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(controlListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.error = action.payload?.error;
            state.ListingsSuccess = !action.payload?.error;
            state.ListingMsg = action.payload?.msg;
        });
        builder.addCase(deleteListing.fulfilled, (state, action) => {
            state.ListingsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Listings = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ListingMsg = action.payload?.msg;
            state.ListingsSuccess = !action.payload?.error;
        });
    }
});

export const { clearListingMessages } = Listing.actions;

export default Listing.reducer;
