import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getTenantClaims = createAsyncThunk('TenantClaims/getPaginatedClaims', async ({}) => {
    try {
        let url = `/shieldplusclaim/getTenantShieldPlusClaim?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getTenantClaimNotPaginated = createAsyncThunk('TenantClaims/getClaimsNotPaginated', async () => {
    try {
        const { data } = await api.get(`/shieldplusclaim/getTenantShieldPlusClaim`);
        return data;
    } catch (error) {}
});
export const getTenantClaim = createAsyncThunk('TenantClaims/getTenantClaim', async ({ id }) => {
    try {
        const { data } = await api.get(`/shieldplusclaim/getTenantShieldPlusClaim?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteTenantClaim = createAsyncThunk('TenantClaims/deleteTenantClaim', async ({ id }) => {
    try {
        const { data } = await api.delete(`/shieldplusclaim/deleteTenantShieldPlusClaim/${id}`);
        return data;
    } catch (error) {}
});

export const addTenantClaim = createAsyncThunk('TenantClaims/addTenantClaim', async ({ formData }) => {
    try {
        const { data } = await api.post(`/shieldplusclaim/addTenantShieldPlusClaim`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateTenantClaim = createAsyncThunk('TenantClaims/updateTenantClaim', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/shieldplusclaim/editTenantShieldPlusClaim/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
