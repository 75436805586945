import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getLeases = createAsyncThunk('Lease/getPaginatedLease', async ({ property_id }) => {
    try {
        if (property_id) {
            url = `/products/getLease?fisrt_name=${property_id}`;
        } else {
            url = `/products/getLease`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getLeasesNotPaginated = createAsyncThunk('Lease/getLeases', async ({ property_id }) => {
    let url = '';
    if (property_id) {
        url = `/products/getLease?property_id=${property_id}`;
    } else {
        url = `/products/getLease`;
    }
    try {
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
export const getLease = createAsyncThunk('Lease/getLease', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getLease?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteLeases = createAsyncThunk('Lease/deleteLease', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteLease/${id}`);
        return data;
    } catch (error) {}
});

export const addLeases = createAsyncThunk(
    'Lease/addLease',
    async ({
        tenant_id,
        unit_id,
        lease_term,
        start_date,
        end_date,
        rent_amount,
        payment_schedule,
        security_deposit,
        deposit_return_conditions,
        maintenance_responsibilities,
        prohibited_activities,
        early_termination_conditions,
        violation_consequences,
        landlord_signature,
        tenant_signature,
        name,
        late_payment_fee,
        additional_terms
    }) => {
        try {
            const { data } = await api.post(`/products/addLease`, {
                tenant_id,
                unit_id,
                lease_term,
                start_date,
                end_date,
                rent_amount,
                payment_schedule,
                security_deposit,
                deposit_return_conditions,
                maintenance_responsibilities,
                prohibited_activities,
                early_termination_conditions,
                violation_consequences,
                landlord_signature,
                tenant_signature,
                name,
                late_payment_fee,
                additional_terms
            });
            return data;
        } catch (error) {}
    }
);

export const updateLeases = createAsyncThunk(
    'Lease/updateLease',
    async ({
        id,
        tenant_id,
        unit_id,
        lease_term,
        start_date,
        end_date,
        rent_amount,
        payment_schedule,
        security_deposit,
        deposit_return_conditions,
        maintenance_responsibilities,
        prohibited_activities,
        early_termination_conditions,
        violation_consequences,
        landlord_signature,
        tenant_signature,
        status,
        name,
        late_payment_fee,
        additional_terms
    }) => {
        try {
            const { data } = await api.patch(`/products/editLease/${id}`, {
                id,
                tenant_id,
                unit_id,
                lease_term,
                start_date,
                end_date,
                rent_amount,
                payment_schedule,
                security_deposit,
                deposit_return_conditions,
                maintenance_responsibilities,
                prohibited_activities,
                early_termination_conditions,
                violation_consequences,
                landlord_signature,
                tenant_signature,
                status,
                name,
                late_payment_fee,
                additional_terms
            });
            return data;
        } catch (error) {}
    }
);
