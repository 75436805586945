import { createSlice } from '@reduxjs/toolkit';
import { addContacts, deleteContacts, getContact, getContacts, getContactsNotPaginated, updateContacts } from './contactsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    ContactsLoading: false,
    ContactsSuccess: false,
    ContactMsg: '',
    Contacts: [],
    Contact: []
};

const Contact = createSlice({
    name: 'Contact',
    initialState,
    reducers: {
        clearContactMessages: (state) => {
            state.error = false;
            state.ContactMsg = '';
            state.ContactsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getContacts.pending, (state) => {
            state.ContactsLoading = true;
        });
        builder.addCase(getContacts.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Contacts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ContactMsg = action.payload?.msg;
            state.ContactsSuccess = !action.payload?.error;
        });
        builder.addCase(getContact.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.Contact = action.payload?.data[0];
            state.error = action.payload?.error;
            state.ContactMsg = action.payload?.msg;
            state.ContactsSuccess = !action.payload?.error;
        });
        builder.addCase(getContactsNotPaginated.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.ContactsSuccess = !action.payload?.error;
            state.Contacts = action.payload?.data;
            state.error = action.payload?.error;
            state.ContactMsg = action.payload?.msg;
        });
        builder.addCase(addContacts.pending, (state) => {
            state.ContactsLoading = true;
        });
        builder.addCase(addContacts.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.error = action.payload?.error;
            state.ContactsSuccess = !action.payload?.error;
            state.ContactMsg = action.payload?.msg;
        });
        builder.addCase(updateContacts.pending, (state) => {
            state.ContactsLoading = true;
        });
        builder.addCase(updateContacts.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.error = action.payload?.error;
            state.ContactsSuccess = !action.payload?.error;
            state.ContactMsg = action.payload?.msg;
        });
        builder.addCase(deleteContacts.fulfilled, (state, action) => {
            state.ContactsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Contacts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ContactMsg = action.payload?.msg;
            state.ContactsSuccess = !action.payload?.error;
        });
    }
});

export const { clearContactMessages } = Contact.actions;

export default Contact.reducer;
