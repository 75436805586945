import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getAccounts = createAsyncThunk('accounts/getAccounts', async ({ sms, email, get_team_members, get_service_pros }) => {
    try {
        let url = '';
        if (sms) {
            url = `/account/getAccount?sms=${sms}`;
        } else if (email) {
            url = `/account/getAccount?email=${email}`;
        } else if (get_team_members) {
            url = `/account/getAccount?get_team_members=${get_team_members}`;
        } else if (get_service_pros) {
            url = `/account/getAccount?get_service_pros=${get_service_pros}`;
        } else {
            url = `/account/getAccount`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getProfile = createAsyncThunk('accounts/getProfile', async ({}) => {
    try {
        const url = `/account/getProfile`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const updateProfile = createAsyncThunk('accounts/updateProfile', async (formData) => {
    try {
        const url = `/account/editUser`;

        const response = await api.patch(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const userRegister = createAsyncThunk('accounts/register', async ({ first_name, last_name, email, phone, national_id }) => {
    try {
        const { data } = await api.post(`/account/register`, {
            first_name,
            last_name,
            email,
            phone,
            national_id
        });
        return data;
    } catch (error) {}
});
