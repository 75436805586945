import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getUtilityBills = createAsyncThunk('UtilityBills/getPaginatedUtilityBills', async ({ unit_id }) => {
    try {
        let url = '';
        if (charge_category && page) {
            url = `/products/getUtilityBill?unit=${unit_id}&page=${page}`;
        } else {
            url = `/products/getUtilityBill?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getUtilityBillsNotPaginated = createAsyncThunk('UtilityBills/getUtilityBills', async () => {
    try {
        const { data } = await api.get(`/products/getUtilityBill`);
        return data;
    } catch (error) {}
});
export const getUtilityBill = createAsyncThunk('UtilityBills/getUtilityBill', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getUtilityBill?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteUtilityBills = createAsyncThunk('UtilityBills/deleteUtilityBills', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteUtilityBill/${id}`);
        return data;
    } catch (error) {}
});

export const addUtilityBills = createAsyncThunk(
    'UtilityBills/addUtilityBills',
    async ({ unit_id, tenant_id, water, electricity, for_the_month, status, due_date }) => {
        try {
            const { data } = await api.post(`/products/addUtilityBill`, {
                unit_id,
                tenant_id,
                water,
                electricity,
                for_the_month,
                status,
                due_date
            });
            return data;
        } catch (error) {}
    }
);

export const updateUtilityBills = createAsyncThunk(
    'UtilityBills/updateUtilityBills',
    async ({ id, unit_id, tenant_id, water, electricity, for_the_month, status, due_date }) => {
        try {
            const { data } = await api.patch(`/products/editUtilityBill/${id}`, {
                id,
                unit_id,
                tenant_id,
                water,
                electricity,
                for_the_month,
                status,
                due_date
            });
            return data;
        } catch (error) {}
    }
);
