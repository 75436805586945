import { createSlice } from '@reduxjs/toolkit';
import {
    addUnitPrefence,
    deleteUnitPrefence,
    getUnitPrefence,
    getUnitPrefences,
    getUnitPrefencesNotPaginated,
    updateUnitPrefence
} from './userUnitPreferenceActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    UnitPrefencesLoading: false,
    UnitPrefenceSuccess: false,
    UnitPrefenceMsg: '',
    UnitPrefences: [],
    UnitPrefence: null
};

const UnitPrefence = createSlice({
    name: 'UnitPrefence',
    initialState,
    reducers: {
        clearUnitPrefenceMessages: (state) => {
            state.error = false;
            state.UnitPrefenceMsg = '';
            state.UnitPrefenceSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUnitPrefences.pending, (state) => {
            state.UnitPrefencesLoading = true;
        });
        builder.addCase(getUnitPrefences.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.UnitPrefences = action.payload?.data;
            state.error = action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
            state.UnitPrefenceSuccess = !action.payload?.error;
        });
        builder.addCase(getUnitPrefence.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.UnitPrefence = action.payload?.data[0];
            state.error = action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
            state.UnitPrefenceSuccess = !action.payload?.error;
        });
        builder.addCase(getUnitPrefencesNotPaginated.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.UnitPrefenceSuccess = !action.payload?.error;
            state.UnitPrefences = action.payload?.data;
            state.UnitPrefence = action.payload?.data[0];
            state.error = action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
        });
        builder.addCase(addUnitPrefence.pending, (state) => {
            state.UnitPrefencesLoading = true;
        });
        builder.addCase(addUnitPrefence.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.error = action.payload?.error;
            state.UnitPrefenceSuccess = !action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
        });
        builder.addCase(updateUnitPrefence.pending, (state) => {
            state.UnitPrefencesLoading = true;
        });
        builder.addCase(updateUnitPrefence.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.error = action.payload?.error;
            state.UnitPrefenceSuccess = !action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
        });
        builder.addCase(deleteUnitPrefence.fulfilled, (state, action) => {
            state.UnitPrefencesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.UnitPrefences = action.payload?.data.items;
            state.error = action.payload?.error;
            state.UnitPrefenceMsg = action.payload?.msg;
            state.UnitPrefenceSuccess = !action.payload?.error;
        });
    }
});

export const { clearUnitPrefenceMessages } = UnitPrefence.actions;

export default UnitPrefence.reducer;
