import { createSlice } from '@reduxjs/toolkit';
import {
    addLandlordClaim,
    getLandlordClaim,
    deleteLandlordClaim,
    getLandlordClaims,
    getLandlordClaimNotPaginated,
    updateLandlordClaim
} from './landlordShieldPluClaimsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    LandlordClaimLoading: false,
    LandlordClaimSuccess: false,
    LandlordClaimMsg: '',
    LandlordClaims: [],
    LandlordClaim: undefined
};

const LandlordClaim = createSlice({
    name: 'LandlordClaim',
    initialState,
    reducers: {
        clearLandlordClaimMessages: (state) => {
            state.error = false;
            state.LandlordClaimMsg = '';
            state.LandlordClaimSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLandlordClaims.pending, (state) => {
            state.LandlordClaimLoading = true;
        });
        builder.addCase(getLandlordClaims.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.LandlordClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
            state.LandlordClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getLandlordClaim.pending, (state) => {
            state.LandlordClaimLoading = true;
        });
        builder.addCase(getLandlordClaim.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.LandlordClaim = action.payload?.data[0];
            state.error = action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
            state.LandlordClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getLandlordClaimNotPaginated.pending, (state) => {
            state.LandlordClaimLoading = true;
        });
        builder.addCase(getLandlordClaimNotPaginated.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.LandlordClaimSuccess = !action.payload?.error;
            state.LandlordClaims = action.payload?.data;
            state.error = action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
        });
        builder.addCase(addLandlordClaim.pending, (state) => {
            state.LandlordClaimLoading = true;
        });
        builder.addCase(addLandlordClaim.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.error = action.payload?.error;
            state.LandlordClaimSuccess = !action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
        });
        builder.addCase(updateLandlordClaim.pending, (state) => {
            state.LandlordClaimLoading = true;
        });
        builder.addCase(updateLandlordClaim.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.error = action.payload?.error;
            state.LandlordClaimSuccess = !action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
        });
        builder.addCase(deleteLandlordClaim.fulfilled, (state, action) => {
            state.LandlordClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.LandlordClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LandlordClaimMsg = action.payload?.msg;
            state.LandlordClaimSuccess = !action.payload?.error;
        });
    }
});

export const { clearLandlordClaimMessages } = LandlordClaim.actions;

export default LandlordClaim.reducer;
