import { createSlice } from '@reduxjs/toolkit';
import { sendMessages } from './messagesActions';

const initialState = {
    error: false,
    MessageSuccess: false,
    Messagemsg: '',
    MessagesLoading: false
};

const Messages = createSlice({
    name: 'Messages',
    initialState,
    reducers: {
        clearMessageMessages: (state) => {
            state.error = false;
            state.Messagemsg = '';
            state.MessageSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(sendMessages.pending, (state) => {
            state.MessagesLoading = true;
        });
        builder.addCase(sendMessages.fulfilled, (state, action) => {
            state.MessageSuccess = !action.payload?.error;
            state.Messagemsg = action.payload?.msg;
            state.MessagesLoading = false;
        });
    }
});
export const { clearMessageMessages } = Messages.actions;
export default Messages.reducer;
