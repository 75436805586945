/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { decodeToken, verifyUserAccount } from 'store/features/auth/authActions';
import { clearVerificationMessages } from 'store/features/auth/authSlice';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    Snackbar,
    Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SnackBarAlert from 'utils/snackBarAlert';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const VerifyAccount = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { msg, VerificationSuccess } = useSelector((store) => store.auth);
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const { token } = useParams();
    const decode_token = decodeToken(token);
    const dispatch = useDispatch();
    const [alertOpen, setAlertOpen] = useState(false);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        changePassword('');
    }, []);

    const handleAlertClose = () => {
        dispatch(clearVerificationMessages());
        setAlertOpen(false);
        if (VerificationSuccess) {
            setTimeout(() => {
                navigate(`/login`, { replace: true });
            }, 2000);
        }
    };
    //verify
    const handleVerify = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const password = formData.get('password')?.toString();
        const confirm_password = formData.get('confirm_password')?.toString();

        if (password === confirm_password) {
            dispatch(
                verifyUserAccount({
                    national_id: formData.get('national_id')?.toString(),
                    first_name: formData.get('first_name')?.toString(),
                    last_name: formData.get('last_name')?.toString(),
                    password: formData.get('password')?.toString(),
                    confirm_password: formData.get('confirm_password')?.toString(),
                    user_id: decode_token.user_id,
                    email: decode_token.email,
                    token: token
                })
            );
            setAlertOpen(true);
        }
    };

    return (
        <>
            <Snackbar
                autoHideDuration={3000}
                onClose={handleAlertClose}
                open={alertOpen}
                sx={{ height: '30%' }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {VerificationSuccess ? (
                    <Alert
                        severity="success"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {msg}
                    </Alert>
                ) : (
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {msg}
                    </Alert>
                )}
            </Snackbar>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Verify Email and Set your Password.</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    national_id: '',
                    password: '',
                    confirm_password: ''
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().max(255).required('Password is required'),
                    national_id: Yup.string().max(255).required('Id Number is required'),
                    last_name: Yup.string().max(255).required('Last Name is required'),
                    first_name: Yup.string().max(255).required('First Name is required'),
                    confirm_password: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .max(255)
                        .required('Confirm Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleVerify} {...others}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            {decode_token.first_name == null ? (
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.first_name && errors.first_name)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-email-register">First Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.first_name}
                                            name="first_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.first_name && errors.first_name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.first_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            ) : (
                                ''
                            )}
                            {decode_token.last_name == null ? (
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.last_name && errors.last_name)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-email-register">Last Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.last_name}
                                            name="last_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.last_name && errors.last_name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.last_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                        {decode_token.national_id == null ? (
                            <FormControl
                                fullWidth
                                error={Boolean(touched.national_id && errors.national_id)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-email-register">ID Number</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="number"
                                    value={values.national_id}
                                    name="national_id"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                />
                                {touched.national_id && errors.national_id && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.national_id}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        ) : (
                            ''
                        )}
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password-register"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        label="Password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changePassword(e.target.value);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-register">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirm_password}
                                        name="confirm_password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{}}
                                    />
                                    {touched.confirm_password && errors.confirm_password && (
                                        <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                            {errors.confirm_password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(event) => setChecked(event.target.checked)}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle1">
                                            Agree to our &nbsp;
                                            <Button
                                                onClick={() =>
                                                    window.open(
                                                        'https://www.termsfeed.com/live/ddf0951a-2781-46ed-a775-427581ba99aa',
                                                        '_blank'
                                                    )
                                                }
                                                style={{
                                                    textDecoration: 'none',
                                                    color: theme.palette.primary.main
                                                }}
                                                target="_blank"
                                            >
                                                Terms & Conditions
                                            </Button>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Activate Account
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default VerifyAccount;
