import api from 'api/api';

export const apiGetListings = (params) => {
    return api
        .get('/products/getListing', {
            params: {
                ...params
            }
        })
        .then((response) => response.data);
};
