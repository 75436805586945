import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getNotPaginatedUnits = createAsyncThunk('units/getUnitsWithoutPagination', async () => {
    try {
        const { data } = await api.get(`/config/getUnit`);
        return data;
    } catch (error) {}
});

export const getUnits = createAsyncThunk('units/getUnits', async ({ page, name, from, to }) => {
    try {
        let url = '';
        if (name && from && to) {
            url = `/config/getUnit?page=${page}&name=${name}&from=${from}&to=${to}`;
        } else if (name && from) {
            url = `/config/getUnit?page=${page}&name=${name}&from=${from}`;
        } else if (name && to) {
            url = `/config/getUnit?page=${page}&name=${name}&to=${to}`;
        } else if (from && to) {
            url = `/config/getUnit?page=${page}&from=${from}&to=${to}`;
        } else if (name) {
            url = `/config/getUnit?page=${page}&name=${name}`;
        } else if (from) {
            url = `/config/getUnit?page=${page}&from=${from}`;
        } else if (to) {
            url = `/config/getUnit?page=${page}&to=${to}`;
        } else {
            url = `/config/getUnit?page=${page}`;
        }

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getUnit = createAsyncThunk('units/getUnit', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getUnit?id=${id}`);
        return data;
    } catch (error) {}
});

export const addUnits = createAsyncThunk('Units/addUnits', async ({ units }) => {
    try {
        const { data } = await api.post(`/config/addUnit`, units);
        return data;
    } catch (error) {
        console.log(error);
    }
});

export const updateUnits = createAsyncThunk(
    'units/updateUnits',
    async ({
        id,
        name,
        property_id,
        unit_type_id,
        no_of_bedrooms,
        no_of_bathrooms,
        status,
        tenant_id,
        rent,
        security_deposit,
        square_feet,
        tenantID
    }) => {
        try {
            const { data } = await api.patch(`/config/editUnit/${id}`, {
                id,
                name,
                property_id,
                unit_type_id,
                no_of_bedrooms,
                no_of_bathrooms,
                status,
                tenant_id,
                rent,
                security_deposit,
                square_feet,
                tenantID
            });
            return data;
        } catch (error) {}
    }
);

export const deleteUnits = createAsyncThunk('units/deleteUnits', async ({ page, id }) => {
    try {
        await api.delete(`/config/deleteUnit/${id}`);
        const { data } = await api.get(`/config/getUnit?page=${page}`);
        return data;
    } catch (error) {}
});
