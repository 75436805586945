import { createSlice } from '@reduxjs/toolkit';
import {
    addTenantClaim,
    getTenantClaim,
    deleteTenantClaim,
    getTenantClaims,
    getTenantClaimNotPaginated,
    updateTenantClaim
} from './tenantClaimsAction';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    TenantClaimLoading: false,
    TenantClaimSuccess: false,
    TenantClaimMsg: '',
    TenantClaims: [],
    TenantClaim: undefined
};

const TenantClaim = createSlice({
    name: 'TenantClaim',
    initialState,
    reducers: {
        clearTenantClaimMessages: (state) => {
            state.error = false;
            state.TenantClaimMsg = '';
            state.TenantClaimSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTenantClaims.pending, (state) => {
            state.TenantClaimLoading = true;
        });
        builder.addCase(getTenantClaims.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.TenantClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
            state.TenantClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getTenantClaim.pending, (state) => {
            state.TenantClaimLoading = true;
        });
        builder.addCase(getTenantClaim.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.TenantClaim = action.payload?.data[0];
            state.error = action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
            state.TenantClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getTenantClaimNotPaginated.pending, (state) => {
            state.TenantClaimLoading = true;
        });
        builder.addCase(getTenantClaimNotPaginated.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.TenantClaimSuccess = !action.payload?.error;
            state.TenantClaims = action.payload?.data;
            state.error = action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
        });
        builder.addCase(addTenantClaim.pending, (state) => {
            state.TenantClaimLoading = true;
        });
        builder.addCase(addTenantClaim.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.error = action.payload?.error;
            state.TenantClaimSuccess = !action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
        });
        builder.addCase(updateTenantClaim.pending, (state) => {
            state.TenantClaimLoading = true;
        });
        builder.addCase(updateTenantClaim.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.error = action.payload?.error;
            state.TenantClaimSuccess = !action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
        });
        builder.addCase(deleteTenantClaim.fulfilled, (state, action) => {
            state.TenantClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.TenantClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantClaimMsg = action.payload?.msg;
            state.TenantClaimSuccess = !action.payload?.error;
        });
    }
});

export const { clearTenantClaimMessages } = TenantClaim.actions;

export default TenantClaim.reducer;
