import { createSlice } from '@reduxjs/toolkit';
import { addUnitTypes, deleteUnitTypes, getUnitTypes, getUnitType, getNotPaginatedUnitTypes, updateUnitTypes } from './unitTypeActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    unitTypeSuccess: false,
    unitTypesMsg: '',
    unitTypes: [],
    unitType: undefined,
    unitTypesLoading: false
};
const UnitTypes = createSlice({
    name: 'UnitTypes',
    initialState,
    reducers: {
        clearUnitTypesMessages: (state) => {
            state.error = false;
            state.unitTypesMsg = '';
            state.unitTypeSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUnitTypes.pending, (state) => {
            state.unitTypesLoading = true;
        });
        builder.addCase(getUnitTypes.fulfilled, (state, action) => {
            state.unitTypesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.unitTypes = action.payload?.data.items;
            state.error = action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
            state.unitTypeSuccess = !action.payload?.error;
        });
        builder.addCase(getNotPaginatedUnitTypes.fulfilled, (state, action) => {
            state.unitTypesLoading = false;
            state.unitTypes = action.payload?.data;
            state.error = action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
            state.unitTypeSuccess = !action.payload?.error;
        });

        builder.addCase(addUnitTypes.pending, (state) => {
            state.UnitTypesLoading = true;
        });
        builder.addCase(addUnitTypes.fulfilled, (state, action) => {
            state.UnitTypesLoading = false;
            state.error = action.payload?.error;
            state.unitTypeSuccess = !action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
        });
        builder.addCase(updateUnitTypes.pending, (state) => {
            state.UnitTypesLoading = true;
        });
        builder.addCase(updateUnitTypes.fulfilled, (state, action) => {
            state.UnitTypesLoading = false;
            state.error = action.payload?.error;
            state.unitTypeSuccess = !action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
        });
        builder.addCase(deleteUnitTypes.fulfilled, (state, action) => {
            state.unitTypesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.unitTypes = action.payload?.data.items;
            state.error = action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
            state.unitTypeSuccess = !action.payload?.error;
        });
        builder.addCase(getUnitType.fulfilled, (state, action) => {
            state.unitTypesLoading = false;
            state.unitType = action.payload?.data[0];
            state.error = action.payload?.error;
            state.unitTypesMsg = action.payload?.msg;
            state.unitTypeSuccess = !action.payload?.error;
        });
    }
});

export const { clearUnitTypesMessages } = UnitTypes.actions;

export default UnitTypes.reducer;
