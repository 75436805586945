import { useDispatch, useSelector } from 'react-redux';

export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;

// date formater
export const formatDateTime = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let _date = '';
    let time = '';
    if (dateString !== null) {
        const date = new Date(dateString);
        _date = date.toLocaleDateString(undefined, options);
        time = date.toLocaleTimeString();
    }

    return `${_date} ${time}`;
};

export const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let _date = '';
    if (dateString != null) {
        const formattedDateString = dateString.replace(/\s\+\d{4}$/, '');
        const date = new Date(formattedDateString);
        _date = date.toLocaleDateString(undefined, options);
    }

    return `${_date}`;
};

export const dateFormatter = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Specify the desired format
    let formattedDate = '';
    if (dateString != null) {
        const trimmedDateString = dateString.trim(); // Remove leading and trailing whitespace
        const formattedDateString = trimmedDateString.replace(/\s\+\d{4}$/, '');
        const date = new Date(formattedDateString);
        formattedDate = date.toLocaleDateString('en-CA', options); // 'en-CA' ensures consistent formatting
    }

    return formattedDate;
};

export const isValidDate = (dateString) => {
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    return dateRegex.test(dateString);
};

export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-KE', {
        style: 'currency',
        currency: 'KES',
        maximumFractionDigits: 0
    }).format(amount);
};
