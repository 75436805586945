import { createSlice } from '@reduxjs/toolkit';
import { deleteNotification, getNotifications, getPaginatedNotifications, markAsRead } from './notificationsActions';

const initialState = {
    error: false,
    msg: '',
    notifications: [],
    notificationsLoading: false,
    current_page: 0,
    current_page_items: 0,
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    adminNotifications: []
};

const Notifications = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state) => {
            state.notificationsLoading = true;
        });
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.notifications = action.payload?.data;
            state.notificationsLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
        });
        builder.addCase(getPaginatedNotifications.pending, (state) => {
            state.notificationsLoading = true;
        });
        builder.addCase(getPaginatedNotifications.fulfilled, (state, action) => {
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.adminNotifications = action.payload?.data.items;
            state.notificationsLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
        });

        builder.addCase(markAsRead.fulfilled, (state, action) => {
            state.notifications = action.payload?.data;
            state.notificationsLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
        });
        builder.addCase(deleteNotification.fulfilled, (state, action) => {
            state.notifications = action.payload?.data;
            state.notificationsLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
        });
    }
});

export default Notifications.reducer;
