import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getContacts = createAsyncThunk('Contact/getPaginatedContact', async ({ page, phone, email }) => {
    try {
        let url = '';
        if (email) {
            url = `/contacts/getContact?email=${email}`;
        } else if (phone) {
            url = `/contacts/getContact?phone=${phone}`;
        } else {
            url = `/contacts/getContact?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getContactsNotPaginated = createAsyncThunk('Contact/getContacts', async ({}) => {
    try {
        const { data } = await api.get(`/contacts/getContact`);
        return data;
    } catch (error) {}
});

export const getContact = createAsyncThunk('Contact/getContact', async ({ id }) => {
    try {
        const { data } = await api.get(`/contacts/getContact?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteContacts = createAsyncThunk('Contact/deleteContact', async ({ id }) => {
    try {
        const { data } = await api.delete(`/contacts/deleteContact/${id}`);
        return data;
    } catch (error) {}
});

export const addContacts = createAsyncThunk('Contact/addContact', async ({ name, buy_or_rent, email, phone }) => {
    try {
        const { data } = await api.post(`/contacts/addContact`, {
            name,
            buy_or_rent,
            email,
            phone
        });
        return data;
    } catch (error) {}
});

export const updateContacts = createAsyncThunk('Contact/updateContact', async ({ id, name, buy_or_rent, email, phone }) => {
    try {
        const { data } = await api.patch(`/contacts/editContact/${id}`, {
            id,
            name,
            email,
            phone,
            buy_or_rent
        });
        return data;
    } catch (error) {}
});
