import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getNextKins = createAsyncThunk('Role/getPaginatedNextKins', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/account/getNextKin?page=${page}`;
        } else {
            url = `/account/getNextKin`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getNextKinsNotPaginated = createAsyncThunk('NextKin/getNextKins', async () => {
    try {
        const { data } = await api.get(`/account/getNextKin`);
        return data;
    } catch (error) {}
});
export const getNextKin = createAsyncThunk('NextKin/getNextKin', async ({ id }) => {
    try {
        const { data } = await api.get(`/account/getNextKin?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteNextKin = createAsyncThunk('NextKin/deleteNextKin', async ({ id }) => {
    try {
        const { data } = await api.delete(`/account/deleteNextKin/${id}`);
        return data;
    } catch (error) {}
});

export const addNextKin = createAsyncThunk('NextKin/addNextKin', async ({ phone, first_name, last_name, email, relationship }) => {
    try {
        const { data } = await api.post(`/account/addNextKin`, {
            phone,
            first_name,
            last_name,
            relationship,
            email
        });
        return data;
    } catch (error) {}
});

export const updateNextKin = createAsyncThunk(
    'NextKin/updateNextKin',
    async ({ id, phone, first_name, last_name, relationship, email }) => {
        try {
            const { data } = await api.patch(`/account/editNextKin/${id}`, {
                id,
                phone,
                phone,
                first_name,
                last_name,
                relationship,
                email
            });
            return data;
        } catch (error) {}
    }
);
