import { createSlice } from '@reduxjs/toolkit';
import { getSafeLease, getSafeLeases, getSafeLeasesNotPaginated, updateSafeLeases } from './safeLeaseAction';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    SafeLeasesLoading: false,
    SafeLeaseSuccess: false,
    SafeLeaseMsg: '',
    SafeLeases: [],
    SafeLease: undefined
};

const SafeLease = createSlice({
    name: 'SafeLease',
    initialState,
    reducers: {
        clearSafeLeaseMessages: (state) => {
            state.error = false;
            state.SafeLeaseMsg = '';
            state.SafeLeaseSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSafeLeases.pending, (state) => {
            state.SafeLeasesLoading = true;
        });
        builder.addCase(getSafeLeases.fulfilled, (state, action) => {
            state.SafeLeasesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.SafeLeases = action.payload?.data.items;
            state.error = action.payload?.error;
            state.SafeLeaseMsg = action.payload?.msg;
            state.SafeLeaseSuccess = !action.payload?.error;
        });
        builder.addCase(getSafeLease.pending, (state) => {
            state.SafeLeasesLoading = true;
        });
        builder.addCase(getSafeLease.fulfilled, (state, action) => {
            state.SafeLeasesLoading = false;
            state.SafeLease = action.payload?.data[0];
            state.error = action.payload?.error;
            state.SafeLeaseMsg = action.payload?.msg;
            state.SafeLeaseSuccess = !action.payload?.error;
        });
        builder.addCase(getSafeLeasesNotPaginated.pending, (state) => {
            state.SafeLeasesLoading = true;
        });
        builder.addCase(getSafeLeasesNotPaginated.fulfilled, (state, action) => {
            state.SafeLeasesLoading = false;
            state.SafeLeaseSuccess = !action.payload?.error;
            state.SafeLeases = action.payload?.data;
            state.error = action.payload?.error;
            state.SafeLeaseMsg = action.payload?.msg;
        });
        builder.addCase(updateSafeLeases.pending, (state) => {
            state.SafeLeasesLoading = true;
        });
        builder.addCase(updateSafeLeases.fulfilled, (state, action) => {
            state.SafeLeasesLoading = false;
            state.error = action.payload?.error;
            state.SafeLeaseSuccess = !action.payload?.error;
            state.SafeLeaseMsg = action.payload?.msg;
        });
    }
});

export const { clearSafeLeaseMessages } = SafeLease.actions;

export default SafeLease.reducer;
