import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getRoles = createAsyncThunk('Role/getPaginatedRoles', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/config/getRole?page=${page}`;
        } else {
            url = `/auth/getRole`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getRolesNotPaginated = createAsyncThunk('Role/getRoles', async () => {
    try {
        const { data } = await api.get(`/config/getRole`);
        return data;
    } catch (error) {}
});
export const getRole = createAsyncThunk('Role/getRole', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getRole?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteRole = createAsyncThunk('Role/deleteRole', async ({ id }) => {
    try {
        const { data } = await api.delete(`/config/deleteRole/${id}`);
        return data;
    } catch (error) {}
});

export const addRole = createAsyncThunk('Role/addRole', async ({ name, description }) => {
    try {
        const { data } = await api.post(`/config/addRole`, { name, description });
        return data;
    } catch (error) {}
});

export const updateRole = createAsyncThunk('Role/updateRole', async ({ id, name, description, status }) => {
    try {
        const { data } = await api.patch(`/config/editRole/${id}`, {
            id,
            name,
            description,
            status
        });
        return data;
    } catch (error) {}
});
