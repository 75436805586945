import { createSlice } from '@reduxjs/toolkit';
import { getBanksList } from './listBanksActions';

const initialState = {
    error: false,
    Banks: []
};

const BanksList = createSlice({
    name: 'BanksList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBanksList.fulfilled, (state, action) => {
            state.Banks = action.payload?.data;
            state.error = action.payload?.status;
        });
    }
});

export default BanksList.reducer;
