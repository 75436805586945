import * as React from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const blue = {
    700: '#1976d2',
    500: '#2196f3',
    600: '42a5f5'
};

const grey = {
    100: '#eaeef2',
    300: '#afb8c1',
    900: '#24292f'
};

const CustomButton = styled(Button)(
    ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 22px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};

  &:hover {
    background-color: ${blue[700]};
  }

  &:active {
    background-color: ${blue[700]};
  }

  &:focus-visible {
    box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  `
);

export default CustomButton;
