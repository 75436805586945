import { createSlice } from '@reduxjs/toolkit';
import { addPayments, deletePayments, getPayment, getPayments, getPaymentsNotPaginated, updatePayments } from './paymentsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    PaymentLoading: false,
    PaymentSuccess: false,
    PaymentMsg: '',
    Payments: []
};

const Payment = createSlice({
    name: 'Payment',
    initialState,
    reducers: {
        clearPaymentMessages: (state) => {
            state.error = false;
            state.PaymentMsg = '';
            state.PaymentSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPayments.pending, (state) => {
            state.PaymentLoading = true;
        });
        builder.addCase(getPayments.fulfilled, (state, action) => {
            state.PaymentLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Payments = action.payload?.data.items;
            state.error = action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
            state.PaymentSuccess = !action.payload?.error;
        });
        builder.addCase(getPayment.fulfilled, (state, action) => {
            state.PaymentLoading = false;
            state.Payments = action.payload?.data[0];
            state.error = action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
            state.PaymentSuccess = !action.payload?.error;
        });
        builder.addCase(getPaymentsNotPaginated.fulfilled, (state, action) => {
            state.PaymentLoading = false;
            state.PaymentSuccess = !action.payload?.error;
            state.Payments = action.payload?.data;
            state.error = action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
        });
        builder.addCase(addPayments.pending, (state) => {
            state.PaymentLoading = true;
        });
        builder.addCase(addPayments.fulfilled, (state, action) => {
            state.PaymentsLoading = false;
            state.error = action.payload?.error;
            state.PaymentSuccess = !action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
        });
        builder.addCase(updatePayments.pending, (state) => {
            state.PaymentLoading = true;
        });
        builder.addCase(updatePayments.fulfilled, (state, action) => {
            state.PaymentsLoading = false;
            state.error = action.payload?.error;
            state.PaymentSuccess = !action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
        });
        builder.addCase(deletePayments.fulfilled, (state, action) => {
            state.PaymentsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Payments = action.payload?.data.items;
            state.error = action.payload?.error;
            state.PaymentMsg = action.payload?.msg;
            state.PaymentSuccess = !action.payload?.error;
        });
    }
});

export const { clearPaymentMessages } = Payment.actions;

export default Payment.reducer;
