import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiGetListings } from './api';

export const reduxGetListings = createAsyncThunk('GET_LISTINGS', async (params, { rejectWithValue }) => {
    return apiGetListings(params).catch((e) => {
        if (isAxiosError(e)) return rejectWithValue(e.response.data);
        return Promise.reject(e);
    });
});
