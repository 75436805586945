import { createSlice } from '@reduxjs/toolkit';
import {
    addEmployment,
    deleteEmployment,
    getEmployment,
    getEmployments,
    getEmploymentsNotPaginated,
    updateEmployment
} from './employmentActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    EmploymentsLoading: false,
    EmploymentSuccess: false,
    EmploymentMsg: '',
    Employments: [],
    Employment: null
};

const Employment = createSlice({
    name: 'Employment',
    initialState,
    reducers: {
        clearEmploymentMessages: (state) => {
            state.error = false;
            state.EmploymentMsg = '';
            state.EmploymentSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployments.pending, (state) => {
            state.EmploymentsLoading = true;
        });
        builder.addCase(getEmployments.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Employments = action.payload?.data;
            state.error = action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
            state.EmploymentSuccess = !action.payload?.error;
        });
        builder.addCase(getEmployment.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.Employment = action.payload?.data[0];
            state.error = action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
            state.EmploymentSuccess = !action.payload?.error;
        });
        builder.addCase(getEmploymentsNotPaginated.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.EmploymentSuccess = !action.payload?.error;
            state.Employments = action.payload?.data;
            state.Employment = action.payload?.data[0];
            state.error = action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
        });
        builder.addCase(addEmployment.pending, (state) => {
            state.EmploymentsLoading = true;
        });
        builder.addCase(addEmployment.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.error = action.payload?.error;
            state.EmploymentSuccess = !action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
        });
        builder.addCase(updateEmployment.pending, (state) => {
            state.EmploymentsLoading = true;
        });
        builder.addCase(updateEmployment.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.error = action.payload?.error;
            state.EmploymentSuccess = !action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
        });
        builder.addCase(deleteEmployment.fulfilled, (state, action) => {
            state.EmploymentsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Employments = action.payload?.data.items;
            state.error = action.payload?.error;
            state.EmploymentMsg = action.payload?.msg;
            state.EmploymentSuccess = !action.payload?.error;
        });
    }
});

export const { clearEmploymentMessages } = Employment.actions;

export default Employment.reducer;
