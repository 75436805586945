import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getNotifications = createAsyncThunk('notifications/getNotifications', async ({}) => {
    try {
        let url = `/products/getNotification`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getPaginatedNotifications = createAsyncThunk(
    'notifications/getPaginatedNotifications',
    async ({ page, notification, from, to }) => {
        try {
            let url = '';
            if (notification && from && to) {
                url = `/products/getNotification?notification=${notification}&from=${from}&to=${to}&page=${page}`;
            } else if (notification && from) {
                url = `/products/getNotification?notification=${notification}&from=${from}&page=${page}`;
            } else if (notification && to) {
                url = `/products/getNotification?notification=${notification}&to=${to}&page=${page}`;
            } else if (from && to) {
                url = `/products/getNotification?from=${from}&to=${to}&page=${page}`;
            } else if (notification) {
                url = `/products/getNotification?notification=${notification}&page=${page}`;
            } else if (from) {
                url = `/products/getNotification?from=${from}&page=${page}`;
            } else if (to) {
                url = `/products/getNotification?to=${to}&page=${page}`;
            } else {
                url = `/products/getNotification?page=${page}`;
            }

            const { data } = await api.get(url);
            return data;
        } catch (error) {}
    }
);

export const markAsRead = createAsyncThunk('notifications/markAsRead', async ({ id }) => {
    try {
        await api.patch(`/products/editNotification/${id}`);
        const { data } = await api.get(`/products/getNotification`);
        return data;
    } catch (error) {}
});

export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async ({ id }) => {
    try {
        await api.delete(`/products/deleteNotification/${id}`);
        const { data } = await api.get(`/products/getNotification`);
        return data;
    } catch (error) {}
});
