import { createSlice } from '@reduxjs/toolkit';
import { addRole, deleteRole, getRole, getRoles, getRolesNotPaginated, updateRole } from './rolesActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    RolesLoading: false,
    Rolesuccess: false,
    RoleMsg: '',
    roles: [],
    role: null
};

const Role = createSlice({
    name: 'Role',
    initialState,
    reducers: {
        clearRoleMessages: (state) => {
            state.error = false;
            state.RoleMsg = '';
            state.Rolesuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.pending, (state) => {
            state.RolesLoading = true;
        });
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.roles = action.payload?.data;
            state.error = action.payload?.error;
            state.RoleMsg = action.payload?.msg;
            state.Rolesuccess = !action.payload?.error;
        });
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.role = action.payload?.data[0];
            state.error = action.payload?.error;
            state.RoleMsg = action.payload?.msg;
            state.Rolesuccess = !action.payload?.error;
        });
        builder.addCase(getRolesNotPaginated.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.Rolesuccess = !action.payload?.error;
            state.roles = action.payload?.data;
            state.error = action.payload?.error;
            state.RoleMsg = action.payload?.msg;
        });
        builder.addCase(addRole.pending, (state) => {
            state.RolesLoading = true;
        });
        builder.addCase(addRole.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.error = action.payload?.error;
            state.Rolesuccess = !action.payload?.error;
            state.RoleMsg = action.payload?.msg;
        });
        builder.addCase(updateRole.pending, (state) => {
            state.RolesLoading = true;
        });
        builder.addCase(updateRole.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.error = action.payload?.error;
            state.Rolesuccess = !action.payload?.error;
            state.RoleMsg = action.payload?.msg;
        });
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.RolesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.roles = action.payload?.data.items;
            state.error = action.payload?.error;
            state.RoleMsg = action.payload?.msg;
            state.Rolesuccess = !action.payload?.error;
        });
    }
});

export const { clearRoleMessages } = Role.actions;

export default Role.reducer;
