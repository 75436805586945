import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../api/api';
import { authLogout } from '../../store/features/auth/authActions';

function Interceptor() {
    const dispatch = useDispatch();
    const { accessToken, refreshToken } = useSelector((store) => store.auth);

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use((config) => {
            if (config.url === '/account/refreshToken') {
                config.headers = {
                    ...config.headers,
                    ...(!!refreshToken && { 'refresh-token': refreshToken })
                };
            } else {
                config.headers = {
                    ...config.headers,
                    ...(!!accessToken && { 'access-token': accessToken }),
                    source: 0
                };
            }
            return config;
        });

        const responseInterceptor = api.interceptors.response.use(
            (res) => res,
            async (err) => {
                if (err.response.status === 401) {
                    window.location.reload();
                }
                return Promise.reject(err);
            }
        );

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [accessToken, refreshToken, dispatch]);

    return null;
}

export default Interceptor;
