import { createSlice } from '@reduxjs/toolkit';
import { addLeases, deleteLeases, getLease, getLeases, getLeasesNotPaginated, updateLeases } from './leaseActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    LeasesLoading: false,
    LeasesSuccess: false,
    LeaseMsg: '',
    Leases: []
};

const Lease = createSlice({
    name: 'Lease',
    initialState,
    reducers: {
        clearLeaseMessages: (state) => {
            state.error = false;
            state.LeaseMsg = '';
            state.LeasesSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLeases.pending, (state) => {
            state.LeasesLoading = true;
        });
        builder.addCase(getLeases.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Leases = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
            state.LeasesSuccess = !action.payload?.error;
        });
        builder.addCase(getLease.pending, (state) => {
            state.LeasesLoading = true;
        });
        builder.addCase(getLease.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.Leases = action.payload?.data[0];
            state.error = action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
            state.LeasesSuccess = !action.payload?.error;
        });
        builder.addCase(getLeasesNotPaginated.pending, (state) => {
            state.LeasesLoading = true;
        });
        builder.addCase(getLeasesNotPaginated.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.LeasesSuccess = !action.payload?.error;
            state.Leases = action.payload?.data;
            state.error = action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
        });
        builder.addCase(addLeases.pending, (state) => {
            state.LeasesLoading = true;
        });
        builder.addCase(addLeases.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.error = action.payload?.error;
            state.LeasesSuccess = !action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
        });
        builder.addCase(updateLeases.pending, (state) => {
            state.LeasesLoading = true;
        });
        builder.addCase(updateLeases.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.error = action.payload?.error;
            state.LeasesSuccess = !action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
        });
        builder.addCase(deleteLeases.fulfilled, (state, action) => {
            state.LeasesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Leases = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LeaseMsg = action.payload?.msg;
            state.LeasesSuccess = !action.payload?.error;
        });
    }
});

export const { clearLeaseMessages } = Lease.actions;

export default Lease.reducer;
