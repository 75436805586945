/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

// Styled component with multiple animations and positioning at the center top
const FancyLoader = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
    animation: `spin 1s linear infinite, bounce 2s ease-in-out infinite`,
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translateX(-50%)', // Correct horizontal centering
    '@keyframes spin': {
        '100%': { transform: 'rotate(360deg)' }
    },
    '@keyframes bounce': {
        '0%, 100%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(-10px)' }
    },
    '& svg': {
        stroke: 'rgba(255, 255, 255, 0.4)' /* Add a subtle stroke */,
        strokeDasharray: '80',
        strokeDashoffset: '0',
        animation: 'dash 1.5s ease-in-out infinite',
        '@keyframes dash': {
            '0%': { strokeDashoffset: '80' },
            '50%': { strokeDashoffset: '40' },
            '100%': { strokeDashoffset: '80' }
        }
    }
}));

const SnackBarAlert = ({ alertMessage }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (alertMessage) {
            setLoading(false);
        }
    }, [alertMessage]);

    return <>{loading && <FancyLoader />}</>;
};

export default SnackBarAlert;
