import { createSlice } from '@reduxjs/toolkit';
import {
    addMaintenance,
    deleteMaintenance,
    getMaintenance,
    getMaintenances,
    getMaintenancesNotPaginated,
    updateMaintenance
} from './maintenanceActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    MaintenancesLoading: false,
    MaintenanceSuccess: false,
    MaintenanceMsg: '',
    maintenances: [],
    maintenance: null
};

const Maintenance = createSlice({
    name: 'Maintenance',
    initialState,
    reducers: {
        clearMaintenanceMessages: (state) => {
            state.error = false;
            state.MaintenanceMsg = '';
            state.MaintenanceSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMaintenances.pending, (state) => {
            state.MaintenancesLoading = true;
        });
        builder.addCase(getMaintenances.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.maintenances = action.payload?.data;
            state.error = action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
            state.MaintenanceSuccess = !action.payload?.error;
        });
        builder.addCase(getMaintenance.pending, (state) => {
            state.MaintenancesLoading = true;
        });
        builder.addCase(getMaintenance.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.maintenance = action.payload?.data[0];
            state.error = action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
            state.MaintenanceSuccess = !action.payload?.error;
        });
        builder.addCase(getMaintenancesNotPaginated.pending, (state) => {
            state.MaintenancesLoading = true;
        });
        builder.addCase(getMaintenancesNotPaginated.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.MaintenanceSuccess = !action.payload?.error;
            state.maintenances = action.payload?.data;
            state.error = action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
        });
        builder.addCase(addMaintenance.pending, (state) => {
            state.MaintenancesLoading = true;
        });
        builder.addCase(addMaintenance.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.error = action.payload?.error;
            state.MaintenanceSuccess = !action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
        });
        builder.addCase(updateMaintenance.pending, (state) => {
            state.MaintenancesLoading = true;
        });
        builder.addCase(updateMaintenance.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.error = action.payload?.error;
            state.MaintenanceSuccess = !action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
        });
        builder.addCase(deleteMaintenance.fulfilled, (state, action) => {
            state.MaintenancesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.maintenances = action.payload?.data.items;
            state.error = action.payload?.error;
            state.MaintenanceMsg = action.payload?.msg;
            state.MaintenanceSuccess = !action.payload?.error;
        });
    }
});

export const { clearMaintenanceMessages } = Maintenance.actions;

export default Maintenance.reducer;
