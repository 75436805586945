import { createSlice } from '@reduxjs/toolkit';
import { addMoveOut, deleteMoveOut, getMoveOut, getMoveOuts, getMoveOutNotPaginated, updateMoveOut } from './moveOutActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    MoveOutsLoading: false,
    MoveOutSuccess: false,
    MoveOutMsg: '',
    MoveOuts: [],
    MoveOut: undefined
};

const MoveOut = createSlice({
    name: 'MoveOut',
    initialState,
    reducers: {
        clearMoveOutMessages: (state) => {
            state.error = false;
            state.MoveOutMsg = '';
            state.MoveOutSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMoveOuts.pending, (state) => {
            state.MoveOutsLoading = true;
        });
        builder.addCase(getMoveOuts.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.MoveOuts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
            state.MoveOutSuccess = !action.payload?.error;
        });
        builder.addCase(getMoveOut.pending, (state) => {
            state.MoveOutsLoading = true;
        });
        builder.addCase(getMoveOut.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.MoveOut = action.payload?.data[0];
            state.error = action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
            state.MoveOutSuccess = !action.payload?.error;
        });
        builder.addCase(getMoveOutNotPaginated.pending, (state) => {
            state.MoveOutsLoading = true;
        });
        builder.addCase(getMoveOutNotPaginated.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.MoveOutSuccess = !action.payload?.error;
            state.MoveOuts = action.payload?.data;
            state.error = action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
        });
        builder.addCase(addMoveOut.pending, (state) => {
            state.MoveOutsLoading = true;
        });
        builder.addCase(addMoveOut.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.error = action.payload?.error;
            state.MoveOutSuccess = !action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
        });
        builder.addCase(updateMoveOut.pending, (state) => {
            state.MoveOutsLoading = true;
        });
        builder.addCase(updateMoveOut.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.error = action.payload?.error;
            state.MoveOutSuccess = !action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
        });
        builder.addCase(deleteMoveOut.fulfilled, (state, action) => {
            state.MoveOutsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.MoveOuts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.MoveOutMsg = action.payload?.msg;
            state.MoveOutSuccess = !action.payload?.error;
        });
    }
});

export const { clearMoveOutMessages } = MoveOut.actions;

export default MoveOut.reducer;
