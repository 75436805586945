import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { decodeToken } from './authActions';

const selectAuthState = (state) => {
    return state.auth;
};

export const selectAuthUser = createDraftSafeSelector(selectAuthState, (state) => {
    const userTokenPermissions = decodeToken(state.accessToken);
    const isAuthenticated = !!userTokenPermissions && Date.now() < userTokenPermissions.exp * 1000;
    const userType = userTokenPermissions?.user_type;
    const isVerified = userTokenPermissions?.is_verified;
    const userId = userTokenPermissions?.user_id;
    const isAdmin = userTokenPermissions?.is_admin;

    return {
        ...state,
        userTokenPermissions,
        isAuthenticated,
        userType,
        isVerified,
        userId,
        isAdmin
    };
});
