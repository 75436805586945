import { createSlice } from '@reduxjs/toolkit';
import {
    addTenantShieldPlus,
    getTenantShieldPlus,
    deleteTenantShieldPlus,
    getTenantShieldPluses,
    getTenantShieldPlusNotPaginated,
    updateTenantShieldPlus
} from './tenantShieldPlusActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    TenantShieldPlusLoading: false,
    TenantShieldPlusesuccess: false,
    TenantShieldPlusMsg: '',
    TenantShieldPluses: [],
    TenantShieldPlus: undefined
};

const TenantShieldPlus = createSlice({
    name: 'TenantShieldPlus',
    initialState,
    reducers: {
        clearTenantShieldPlusMessages: (state) => {
            state.error = false;
            state.TenantShieldPlusMsg = '';
            state.TenantShieldPlusesuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTenantShieldPluses.pending, (state) => {
            state.TenantShieldPlusLoading = true;
        });
        builder.addCase(getTenantShieldPluses.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.TenantShieldPluses = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
            state.TenantShieldPlusesuccess = !action.payload?.error;
        });
        builder.addCase(getTenantShieldPlus.pending, (state) => {
            state.TenantShieldPlusLoading = true;
        });
        builder.addCase(getTenantShieldPlus.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.TenantShieldPlus = action.payload?.data[0];
            state.error = action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
            state.TenantShieldPlusesuccess = !action.payload?.error;
        });
        builder.addCase(getTenantShieldPlusNotPaginated.pending, (state) => {
            state.TenantShieldPlusLoading = true;
        });
        builder.addCase(getTenantShieldPlusNotPaginated.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.TenantShieldPlusesuccess = !action.payload?.error;
            state.TenantShieldPluses = action.payload?.data;
            state.error = action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(addTenantShieldPlus.pending, (state) => {
            state.TenantShieldPlusLoading = true;
        });
        builder.addCase(addTenantShieldPlus.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.error = action.payload?.error;
            state.TenantShieldPlusesuccess = !action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(updateTenantShieldPlus.pending, (state) => {
            state.TenantShieldPlusLoading = true;
        });
        builder.addCase(updateTenantShieldPlus.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.error = action.payload?.error;
            state.TenantShieldPlusesuccess = !action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(deleteTenantShieldPlus.fulfilled, (state, action) => {
            state.TenantShieldPlusLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.TenantShieldPluses = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantShieldPlusMsg = action.payload?.msg;
            state.TenantShieldPlusesuccess = !action.payload?.error;
        });
    }
});

export const { clearTenantShieldPlusMessages } = TenantShieldPlus.actions;

export default TenantShieldPlus.reducer;
