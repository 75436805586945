import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getListings = createAsyncThunk('Listing/getPaginatedListing', async ({}) => {
    try {
        let url = `/products/getListing?page=${page}`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getListingNotPaginated = createAsyncThunk('Listing/getListings', async () => {
    try {
        const { data } = await api.get(`/products/getListing`);
        return data;
    } catch (error) {}
});

export const fetchListingNotPaginated = createAsyncThunk('Listing/fetchListings', async () => {
    try {
        const { data } = await api.get(`/products/fetchListing`);
        return data;
    } catch (error) {}
});

export const getAdminListing = createAsyncThunk('Listing/fetchListing', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/fetchListing?id=${id}`);
        return data;
    } catch (error) {}
});

export const getListing = createAsyncThunk('Listing/getListing', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getListing?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteListing = createAsyncThunk('Listing/deleteListing', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteListing/${id}`);
        return data;
    } catch (error) {}
});

export const addListing = createAsyncThunk('Listing/addListing', async ({ formData }) => {
    try {
        const { data } = await api.post(`/products/addListing`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateListing = createAsyncThunk('Listing/updateListing', async ({ id, formData }) => {
    try {
        const { data } = await api.patch(`/products/editListing/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const controlListing = createAsyncThunk('Listing/controlListing', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/products/updateListing/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
