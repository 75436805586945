// assets
import { IconSettings, IconBrandGravatar, IconBriefcase, IconHelp } from '@tabler/icons';

// constant
const icons = { IconSettings, IconBriefcase, IconBrandGravatar, IconHelp };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            icon: icons.IconHelp,
            url: '/help',
            target: false
        }
    ]
};

export default utilities;
