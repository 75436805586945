// assets
import {
    IconUsers,
    IconUser,
    IconHome,
    IconHomeQuestion,
    IconCurrencyDollar,
    IconChartInfographic,
    IconMessage,
    IconTool,
    IconAd2,
    IconSettings,
    IconDashboard,
    IconBriefcase,
    IconWriting,
    IconPaint,
    IconBuildingSkyscraper,
    IconMoneybag,
    IconFileDollar,
    IconHelp,
    IconFriends,
    IconUserCircle,
    IconHomeDollar,
    IconCalculator,
    IconDeviceAnalytics,
    IconShieldCheck
} from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconUser,
    IconHome,
    IconHomeQuestion,
    IconCurrencyDollar,
    IconChartInfographic,
    IconMessage,
    IconTool,
    IconAd2,
    IconSettings,
    IconDashboard,
    IconBriefcase,
    IconWriting,
    IconPaint,
    IconBuildingSkyscraper,
    IconMoneybag,
    IconFileDollar,
    IconHelp,
    IconFriends,
    IconUserCircle,
    IconHomeDollar,
    IconCalculator,
    IconDeviceAnalytics,
    IconShieldCheck
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    caption: 'Pages',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            icon: icons.IconDashboard,
            url: 'admin/dashboard',
            target: false
        },
        {
            id: 'rnpl',
            title: 'Rent Now Pay Later',
            type: 'item',
            icon: icons.IconHomeDollar,
            url: 'admin/rnpl',
            target: false
        },
        // {
        //     id: 'shieldplus',
        //     title: 'ShieldPlus',
        //     type: 'item',
        //     icon: icons.IconShieldCheck,
        //     url: 'admin/shieldplus',
        //     target: false
        // },
        {
            id: 'accounting',
            title: 'Payments',
            type: 'item',
            icon: icons.IconCurrencyDollar,
            url: 'admin/accounting',
            target: false
        },
        {
            id: 'applications',
            title: 'Leads',
            type: 'item',
            icon: icons.IconHomeQuestion,
            url: 'admin/applications',
            target: false
        },
        {
            id: 'maintenace',
            title: 'Maintence & Services',
            type: 'item',
            icon: icons.IconPaint,
            url: 'admin/maintenance',
            target: false
        },
        {
            id: 'properties',
            title: 'Properties',
            type: 'item',
            icon: icons.IconHome,
            url: 'admin/properties',
            target: false
        },
        {
            id: 'leases',
            title: 'Leases',
            type: 'item',
            icon: icons.IconWriting,
            url: 'admin/leases',
            target: false
        },
        {
            id: 'listings',
            title: 'Listings',
            type: 'item',
            url: 'admin/listings',
            icon: icons.IconAd2,
            target: false
        },
        {
            id: 'contants',
            title: 'Contacts',
            type: 'collapse',
            icon: icons.IconWriting,
            children: [
                {
                    id: 'tenants',
                    title: 'Tenants',
                    type: 'item',
                    icon: icons.IconFriends,
                    url: 'admin/tenants',
                    target: false
                },
                {
                    id: 'team',
                    title: 'Team',
                    type: 'item',
                    icon: icons.IconUsers,
                    url: 'admin/team',
                    target: false
                }
            ]
        }
    ]
};

export default pages;
