import { createSlice } from '@reduxjs/toolkit';
import { authLogin, authLogout, decodeToken, getProfile, verifyUserAccount, ResetPassword, verifyPasswordReset } from './authActions';
import { userState } from './authState';

const initialState = {
    user: userState,
    isAuthLoading: false,
    userProfile: {},
    isProfileLoading: false,
    isAuthenticated: false,
    isTokenRefresh: false,
    isRefreshTokenExpired: false,
    error: false,
    VerificationSuccess: false,
    PassWordResetSuccess: false,
    msg: '',
    authErrors: {
        accountError: {
            error: false,
            msg: ''
        },
        refreshToken: {
            error: false,
            msg: ''
        }
    },
    accessToken: '',
    refreshToken: '',
    isLogout: false
};

const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        decodedToken: (state) => {
            let token = decodeToken(state.accessToken);
            state.userTokenPermissions = token;
        },
        clearVerificationMessages: (state) => {
            state.error = false;
            state.msg = '';
            state.VerificationSuccess = false;
        },

        clearAccountError: (state) => {
            state.authErrors.accountError.error = false;
            state.authErrors.accountError.msg = '';
            state.isLogout = false;
            state.msg = '';
        },
        setRefreshToken: (state) => {
            state.isTokenRefresh = !state.isTokenRefresh;
        },
        refreshToken: (state, action) => {
            state.user.access_token = action.payload?.data?.access_token;
            state.user.refresh_token = action.payload?.data?.refresh_token;
            state.accessToken = state.user.access_token;
            state.refreshToken = state.user.refresh_token;
            state.isTokenRefresh = false;
            state.authErrors.refreshToken.error = action.payload?.error;
            state.authErrors.refreshToken.msg = action.payload?.msg;
            state.msg = action.payload?.msg;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authLogin.pending, (state) => {
            state.isAuthLoading = true;
        });
        builder.addCase(authLogin.fulfilled, (state, action) => {
            state.user = action.payload?.data;
            state.accessToken = state.user?.access_token;
            state.refreshToken = state.user?.refresh_token;
            state.isTokenRefresh = false;
            state.isAuthLoading = false;
            state.isAuthenticated = action.payload?.data === null ? false : true;
            state.authErrors.accountError.error = action.payload?.error;
            state.authErrors.accountError.msg = action.payload?.msg;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
        });
        builder.addCase(authLogout.fulfilled, (state, action) => {
            state.user = userState;
            state.accessToken = '';
            state.refreshToken = '';
            state.isAuthenticated = false;
            state.isTokenRefresh = false;
            state.error = false;
            state.isLogout = !action.payload?.error;
            state.msg = action.payload?.msg;
        });
        builder.addCase(verifyUserAccount.pending, (state) => {
            state.isAuthLoading = true;
        });
        builder.addCase(verifyUserAccount.fulfilled, (state, action) => {
            state.isAuthLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
            state.VerificationSuccess = !action.payload?.error;
        });

        builder.addCase(verifyPasswordReset.pending, (state) => {
            state.isAuthLoading = true;
        });
        builder.addCase(verifyPasswordReset.fulfilled, (state, action) => {
            state.isAuthLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
            state.VerificationSuccess = !action.payload?.error;
        });

        builder.addCase(ResetPassword.pending, (state) => {
            state.isAuthLoading = true;
        });
        builder.addCase(ResetPassword.fulfilled, (state, action) => {
            state.isAuthLoading = false;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
            state.PassWordResetSuccess = !action.payload?.error;
        });

        builder.addCase(getProfile.pending, (state) => {
            state.isProfileLoading = true;
        });
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.isProfileLoading = false;
            state.userProfile = action.payload?.data;
        });
    }
});

export const { refreshToken, setRefreshToken, clearAccountError, clearVerificationMessages, decodedToken } = authSlice.actions;

export default authSlice.reducer;
