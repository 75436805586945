/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
import VerifyAccount from 'views/pages/authentication/authentication/Verify';
import PasswordReset from 'views/pages/authentication/authentication/ResetPassword';
import RequestPasswordReset from 'views/pages/authentication/authentication/RequestPasswordReset';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'verify/:token',
            element: <VerifyAccount />
        },
        {
            path: 'reset-password',
            element: <RequestPasswordReset />
        },
        {
            path: 'reset-password/:token',
            element: <PasswordReset />
        }
    ]
};

export default AuthenticationRoutes;
