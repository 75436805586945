import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getLandlordClaims = createAsyncThunk('LandlordClaims/getPaginatedClaims', async ({}) => {
    try {
        let url = `/shieldplusclaim/getLandlordShieldPlusClaim?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getLandlordClaimNotPaginated = createAsyncThunk('LandlordClaims/getClaimsNotPaginated', async () => {
    try {
        const { data } = await api.get(`/shieldplusclaim/getLandlordShieldPlusClaim`);
        return data;
    } catch (error) {}
});
export const getLandlordClaim = createAsyncThunk('LandlordClaims/getLandlordClaim', async ({ id }) => {
    try {
        const { data } = await api.get(`/shieldplusclaim/getLandlordShieldPlusClaim?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteLandlordClaim = createAsyncThunk('LandlordClaims/deleteLandlordClaim', async ({ id }) => {
    try {
        const { data } = await api.delete(`/shieldplusclaim/deleteLandlordShieldPlusClaim/${id}`);
        return data;
    } catch (error) {}
});

export const addLandlordClaim = createAsyncThunk('LandlordClaims/addLandlordClaim', async ({ formData }) => {
    try {
        const { data } = await api.post(`/shieldplusclaim/addLandlordShieldPlusClaim`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateLandlordClaim = createAsyncThunk('LandlordClaims/updateLandlordClaim', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/shieldplusclaim/editLandlordShieldPlusClaim/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
