/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ApplicationsPage from 'views/pages/applications/index';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AuthGuard = Loadable(lazy(() => import('views/utilities/authGuard')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// pages routing
const PropertyPage = Loadable(lazy(() => import('views/pages/properties/index')));
const PropertyForm = Loadable(lazy(() => import('components/property/propertyForm')));
const PropertyDetailsPage = Loadable(lazy(() => import('views/pages/properties/propertyDetails')));
const ReportsPage = Loadable(lazy(() => import('views/pages/reports/index')));
const AccountingPage = Loadable(lazy(() => import('views/pages/accounting/index')));
const TenantsPage = Loadable(lazy(() => import('components/people/tenants/tenants')));
const TeamsPage = Loadable(lazy(() => import('components/people/team/team')));
const LeasePage = Loadable(lazy(() => import('views/pages/leasing/index')));
const LeaseDetails = Loadable(lazy(() => import('views/pages/leasing/leaseDetails')));
const LeaseForm = Loadable(lazy(() => import('views/pages/leasing/leaseForm')));
const ApplicationPage = Loadable(lazy(() => import('views/pages/applications/index')));
const ApplicationDetailsPage = Loadable(lazy(() => import('views/pages/applications/applicationDetails')));
const MaintenancePage = Loadable(lazy(() => import('views/pages/maintenance/index')));
const Maintenance = Loadable(lazy(() => import('components/maintenance/maintenance')));
const MaintenanceDetailsPage = Loadable(lazy(() => import('components/maintenance/maintenanceDetails')));
const MarketingPage = Loadable(lazy(() => import('views/pages/marketing/index')));
const ListingDetails = Loadable(lazy(() => import('components/marketings/listingsDetails')));
const ListingForm = Loadable(lazy(() => import('components/marketings/listingsForm')));
const CommunicationsPage = Loadable(lazy(() => import('views/pages/communications/index')));
const MessageForm = Loadable(lazy(() => import('components/communications/messageForm')));
const DocumentPage = Loadable(lazy(() => import('views/pages/documents/index')));
const SettingsPage = Loadable(lazy(() => import('views/pages/settings/index')));
const UnitsPage = Loadable(lazy(() => import('components/units/index')));
const UnitsForm = Loadable(lazy(() => import('components/units/unitsForm')));
const ProfilePage = Loadable(lazy(() => import('views/pages/authentication/account')));
const TenantForm = Loadable(lazy(() => import('components/people/tenants/tenantsForm')));
const UnitDetails = Loadable(lazy(() => import('components/units/unitDetails')));
const TenantsDetails = Loadable(lazy(() => import('components/people/tenants/tenantDetails')));
const UserForm = Loadable(lazy(() => import('components/auth/addUser')));
const HelpPage = Loadable(lazy(() => import('views/pages/help/index')));
const SettlementAccountsForm = Loadable(lazy(() => import('components/payments/settlement-accounts/settlement-account-form')));
const SettlementAccountsPage = Loadable(lazy(() => import('components/payments/settlement-accounts/index')));
const RNPLDetaIlsPage = Loadable(lazy(() => import('components/financing/RNPLDetaIlsView')));
const UtilityBillsPage = Loadable(lazy(() => import('components/accounting/utilities')));
const ChargesPage = Loadable(lazy(() => import('components/accounting/charges')));
const CampaignsPage = Loadable(lazy(() => import('views/pages/marketing/campaigns')));
const CampaignsForm = Loadable(lazy(() => import('components/marketings/campaigns/campaignsForm')));
const ContactsPage = Loadable(lazy(() => import('components/people/contacts/contacts')));
const TenantHistory = Loadable(lazy(() => import('components/people/tenants/history')));
const MoveOuts = Loadable(lazy(() => import('components/units/moveOuts')));
const SafeLeaseDetailsPage = Loadable(lazy(() => import('components/financing/safeLeaseDetails')));
const AdminRNPLView = Loadable(lazy(() => import('components/financing/adminIndex')));
const AdminSafeLeaseView = Loadable(lazy(() => import('components/financing/adminSafeLeaseIndex')));
const AdminInventory = Loadable(lazy(() => import('components/inventory/adminInventoryIndex')));
const InventoryDetailsPage = Loadable(lazy(() => import('components/inventory/inventoryDetails')));
const LandlordShieldPlusClaimsIndex = Loadable(lazy(() => import('components/shieldplus/landlordShieldPlusClaimsIndex')));
const LandlordShieldPlusIndex = Loadable(lazy(() => import('components/shieldplus/landlordShieldPlusIndex')));
const LandlordClaimsDetailsPage = Loadable(lazy(() => import('components/shieldplus/landLordClaimsDetails')));
const LandlordShieldPlusDetailsPage = Loadable(lazy(() => import('components/shieldplus/landlordShieldPlusDetails')));
const ShieldPlusClaimForm = Loadable(lazy(() => import('components/shieldplus/shieldPlusClaimForm')));
const LandlordShieldPlusForm = Loadable(lazy(() => import('components/shieldplus/landlordShieldPlusForm')));
const PreferencePageIndex = Loadable(lazy(() => import('views/pages/authentication/account/preferenceindex')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <DashboardDefault />
        },
        {
            path: 'properties',
            element: <PropertyPage />
        },
        {
            path: 'properties/create',
            element: <PropertyForm />
        },
        {
            path: 'properties/:prop_id/edit/',
            element: <PropertyForm />
        },
        {
            path: 'properties/:prop_id',
            element: <PropertyDetailsPage />
        },
        {
            path: 'properties/:prop_id/add-units',
            element: <UnitsForm />
        },
        {
            path: 'properties/:prop_id/units/:unit_id',
            element: <UnitDetails />
        },

        {
            path: 'properties/:prop_id/tenants',
            element: <TenantsPage />
        },
        {
            path: 'properties/:prop_id/requests',
            element: <Maintenance />
        },
        {
            path: 'properties/:prop_id/applications',
            element: <ApplicationsPage />
        },
        {
            path: 'units',
            element: <UnitsPage />
        },
        {
            path: 'units/create',
            element: <UnitsForm />
        },
        {
            path: 'units/:unit_id/update',
            element: <UnitsForm />
        },
        {
            path: 'units/:unit_id/details',
            element: <UnitDetails />
        },
        {
            path: 'tenants/create',
            element: <TenantForm />
        },
        {
            path: 'tenants/history',
            element: <TenantHistory />
        },
        {
            path: 'tenants',
            element: <TenantsPage />
        },
        {
            path: 'tenants/move-outs',
            element: <MoveOuts />
        },
        {
            path: 'tenants/:tenant_id/details',
            element: <TenantsDetails />
        },
        {
            path: 'tenants/:tenant_id/update',
            element: <TenantForm />
        },
        {
            path: 'team',
            element: <TeamsPage />
        },
        {
            path: 'team/create',
            element: <UserForm />
        },
        {
            path: 'accounting',
            element: <AccountingPage />
        },
        {
            path: 'accounting/charges',
            element: <ChargesPage />
        },
        {
            path: 'accounting/utility-bills',
            element: <UtilityBillsPage />
        },
        {
            path: 'accounting/settlement-accounts',
            element: <SettlementAccountsPage />
        },
        {
            path: 'accounting/settlement-accounts/add',
            element: <SettlementAccountsForm />
        },
        {
            path: 'reports',
            element: <ReportsPage />
        },
        {
            path: 'people',
            element: <TenantsPage />
        },
        {
            path: 'leases',
            element: <LeasePage />
        },
        {
            path: 'leases/:lease_id',
            element: <LeaseDetails />
        },
        {
            path: 'leases/create',
            element: <LeaseForm />
        },
        {
            path: 'applications',
            element: <ApplicationPage />
        },
        {
            path: 'applications/:id',
            element: <ApplicationDetailsPage />
        },
        {
            path: 'maintenance',
            element: <MaintenancePage />
        },
        {
            path: 'maintenance/:id/details',
            element: <MaintenanceDetailsPage />
        },
        {
            path: 'workorders',
            element: <MaintenancePage />
        },
        {
            path: 'inventory',
            element: <AdminInventory />
        },
        {
            path: 'shieldplus',
            element: <LandlordShieldPlusIndex />
        },
        {
            path: 'shieldplus/:id',
            element: <LandlordShieldPlusDetailsPage />
        },
        {
            path: 'shieldplus/new',
            element: <LandlordShieldPlusForm />
        },
        {
            path: 'claims',
            element: <LandlordShieldPlusClaimsIndex />
        },
        {
            path: 'claims/:id',
            element: <LandlordClaimsDetailsPage />
        },
        {
            path: 'claims/new',
            element: <ShieldPlusClaimForm />
        },
        {
            path: 'inventory/:id',
            element: <InventoryDetailsPage />
        },
        {
            path: 'listings',
            element: <MarketingPage />
        },

        {
            path: 'listings/:listing_id/details',
            element: <ListingDetails />
        },
        {
            path: 'rnpl/:id/details',
            element: <RNPLDetaIlsPage />
        },
        {
            path: 'safelease/:id/',
            element: <SafeLeaseDetailsPage />
        },
        {
            path: 'rnpl',
            element: <AdminRNPLView />
        },
        {
            path: 'safelease',
            element: <AdminSafeLeaseView />
        },
        {
            path: 'listings/create',
            element: <ListingForm />
        },
        {
            path: 'listings/:listing_id/edit',
            element: <ListingForm />
        },
        {
            path: 'campaigns',
            element: <CampaignsPage />
        },
        {
            path: 'contacts',
            element: <ContactsPage />
        },
        {
            path: 'campaigns/create',
            element: <CampaignsForm />
        },
        {
            path: 'communications',
            element: <CommunicationsPage />
        },
        {
            path: 'communications/create',
            element: <MessageForm />
        },
        {
            path: 'files',
            element: <DocumentPage />
        },
        {
            path: 'settings',
            element: <SettingsPage />
        },
        {
            path: 'help',
            element: <HelpPage />
        },
        {
            path: 'user/account',
            element: <ProfilePage />
        },
        {
            path: 'user/preferences',
            element: <PreferencePageIndex />
        },
        {
            path: 'p',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        }
    ]
};

export default MainRoutes;
