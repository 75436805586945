import { createSlice } from '@reduxjs/toolkit';
import { getAccounts, getProfile, updateProfile, userRegister } from './accountsActions';

const initialState = {
    error: false,
    accountsLoading: false,
    accountsSuccess: false,
    msg: '',
    accounts: [],
    profile: null
};

const Accounts = createSlice({
    name: 'Accounts',
    initialState,
    reducers: {
        clearAccountsMessages: (state) => {
            state.error = false;
            state.msg = '';
            state.accountsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccounts.pending, (state) => {
            state.accountsLoading = true;
        });
        builder.addCase(getAccounts.fulfilled, (state, action) => {
            state.accountsLoading = false;
            state.accounts = action.payload?.data;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
            state.accountsSuccess = !action.payload?.error;
        });
        builder.addCase(getProfile.pending, (state) => {
            state.accountsLoading = true;
        });
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.accountsLoading = false;
            state.profile = action.payload?.data;
            state.error = action.payload?.error;
            state.msg = action.payload?.msg;
            state.accountsSuccess = !action.payload?.error;
        });
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.accountsLoading = false;
            state.error = action.payload?.error;
            state.accountsSuccess = !action.payload?.error;
            state.msg = action.payload?.msg;
        });
        builder.addCase(userRegister.fulfilled, (state, action) => {
            state.accountsLoading = false;
            state.error = action.payload?.error;
            state.accountsSuccess = !action.payload?.error;
            state.msg = action.payload?.msg;
        });
    }
});

export const { clearAccountsMessages } = Accounts.actions;

export default Accounts.reducer;
