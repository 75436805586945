import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const sendMessages = createAsyncThunk(
    'Message/sendMessage',
    async ({ message, send_by, send_to, email_subject, recipient_properties }) => {
        try {
            const { data } = await api.post(`/products/addNotification`, {
                message,
                send_by,
                send_to,
                email_subject,
                recipient_properties
            });
            return data;
        } catch (error) {}
    }
);
