import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getNotPaginatedUnitTypes = createAsyncThunk('unitTypes/getUnitTypesWithoutPagination', async () => {
    try {
        const { data } = await api.get(`/config/getUnitType`);
        return data;
    } catch (error) {}
});

export const getUnitTypes = createAsyncThunk('unitTypes/getUnitTypes', async ({ page, name, from, to }) => {
    try {
        let url = '';
        if (name && from && to) {
            url = `/config/getUnitType?page=${page}&name=${name}&from=${from}&to=${to}`;
        } else if (name && from) {
            url = `/config/getUnitType?page=${page}&name=${name}&from=${from}`;
        } else if (name && to) {
            url = `/config/getUnitType?page=${page}&name=${name}&to=${to}`;
        } else if (from && to) {
            url = `/config/getUnitType?page=${page}&from=${from}&to=${to}`;
        } else if (name) {
            url = `/config/getUnitType?page=${page}&name=${name}`;
        } else if (from) {
            url = `/config/getUnitType?page=${page}&from=${from}`;
        } else if (to) {
            url = `/config/getUnitType?page=${page}&to=${to}`;
        } else {
            url = `/config/getUnitType?page=${page}`;
        }

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getUnitType = createAsyncThunk('unitType/getUnitType', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getUnitType?id=${id}`);
        return data;
    } catch (error) {}
});

export const addUnitTypes = createAsyncThunk('unitTypes/addUnitTypes', async ({ name, description }) => {
    try {
        const { data } = await api.post(`/config/addUnitType`, {
            name,
            description
        });
        return data;
    } catch (error) {
        console.log(error);
    }
});

export const updateUnitTypes = createAsyncThunk('unitTypes/updateUnitTypes', async ({ id, name, description }) => {
    try {
        const { data } = await api.patch(`/config/editUnitType/${id}`, {
            id,
            name,
            description
        });
        return data;
    } catch (error) {}
});

export const deleteUnitTypes = createAsyncThunk('unitTypes/deleteUnitTypes', async ({ page, id }) => {
    try {
        await api.delete(`/config/deleteUnitType/${id}`);
        const { data } = await api.get(`/config/getUnitType?page=${page}`);
        return data;
    } catch (error) {}
});
