import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getUsers = createAsyncThunk('Role/getPaginatedUsers', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/account/getUser?page=${page}`;
        } else {
            url = `/auth/getUser`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getUsersNotPaginated = createAsyncThunk('User/getUsers', async () => {
    try {
        const { data } = await api.get(`/account/getUser`);
        return data;
    } catch (error) {}
});
export const getUser = createAsyncThunk('User/getUser', async ({ id }) => {
    try {
        const { data } = await api.get(`/account/getUser?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteUser = createAsyncThunk('User/deleteUser', async ({ id }) => {
    try {
        const { data } = await api.delete(`/account/deleteUser/${id}`);
        return data;
    } catch (error) {}
});

export const addUser = createAsyncThunk(
    'User/addUser',
    async ({ phone, first_name, entity_id, last_name, password, national_id, role, email }) => {
        try {
            const { data } = await api.post(`/account/addUser`, {
                phone,
                first_name,
                last_name,
                password,
                national_id,
                entity_id,
                role,
                email
            });
            return data;
        } catch (error) {}
    }
);

export const updateUser = createAsyncThunk(
    'User/updateUser',
    async ({ id, phone, first_name, middle_name, last_name, password, national_id, role, email }) => {
        try {
            const { data } = await api.patch(`/account/editUser/${id}`, {
                id,
                phone,
                first_name,
                middle_name,
                last_name,
                password,
                national_id,
                role,
                email
            });
            return data;
        } catch (error) {}
    }
);
