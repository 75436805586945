import { createSlice } from '@reduxjs/toolkit';
import {
    addUtilityBills,
    deleteUtilityBills,
    getUtilityBill,
    getUtilityBills,
    getUtilityBillsNotPaginated,
    updateUtilityBills
} from './utilityActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    UtilityBillLoading: false,
    UtilityBillSuccess: false,
    UtilityBillMsg: '',
    UtilityBills: []
};

const UtilityBill = createSlice({
    name: 'UtilityBill',
    initialState,
    reducers: {
        clearUtilityBillMessages: (state) => {
            state.error = false;
            state.UtilityBillMsg = '';
            state.UtilityBillSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUtilityBills.pending, (state) => {
            state.UtilityBillLoading = true;
        });
        builder.addCase(getUtilityBills.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.UtilityBills = action.payload?.data.items;
            state.error = action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
            state.UtilityBillSuccess = !action.payload?.error;
        });
        builder.addCase(getUtilityBill.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.UtilityBills = action.payload?.data[0];
            state.error = action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
            state.UtilityBillSuccess = !action.payload?.error;
        });
        builder.addCase(getUtilityBillsNotPaginated.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.UtilityBillSuccess = !action.payload?.error;
            state.UtilityBills = action.payload?.data;
            state.error = action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
        });
        builder.addCase(addUtilityBills.pending, (state) => {
            state.UtilityBillLoading = true;
        });
        builder.addCase(addUtilityBills.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.error = action.payload?.error;
            state.UtilityBillSuccess = !action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
        });
        builder.addCase(updateUtilityBills.pending, (state) => {
            state.UtilityBillLoading = true;
        });
        builder.addCase(updateUtilityBills.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.error = action.payload?.error;
            state.UtilityBillSuccess = !action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
        });
        builder.addCase(deleteUtilityBills.fulfilled, (state, action) => {
            state.UtilityBillLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.UtilityBills = action.payload?.data.items;
            state.error = action.payload?.error;
            state.UtilityBillMsg = action.payload?.msg;
            state.UtilityBillSuccess = !action.payload?.error;
        });
    }
});

export const { clearUtilityBillMessages } = UtilityBill.actions;

export default UtilityBill.reducer;
