import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getCharges = createAsyncThunk('Charges/getPaginatedCharges', async ({ charge_category }) => {
    try {
        let url = '';
        if (charge_category && page) {
            url = `/products/getUnitCharge?charge_category=${charge_category}&page=${page}`;
        } else {
            url = `/products/getUnitCharge?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getChargesNotPaginated = createAsyncThunk('Charges/getCharges', async () => {
    try {
        const { data } = await api.get(`/products/getUnitCharge`);
        return data;
    } catch (error) {}
});
export const getCharge = createAsyncThunk('Charges/getCharge', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getUnitCharge?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteCharges = createAsyncThunk('Charges/deleteCharges', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteUnitCharge/${id}`);
        return data;
    } catch (error) {}
});

export const addCharges = createAsyncThunk(
    'Charges/addCharges',
    async ({
        unit_id,
        tenant_id,
        charge_category,
        charge_type,
        recurrence,
        percentage_of_rent,
        description,
        start_date,
        end_date,
        amount,
        days_lived,
        due_date
    }) => {
        try {
            const { data } = await api.post(`/products/addUnitCharge`, {
                unit_id,
                tenant_id,
                charge_category,
                charge_type,
                recurrence,
                percentage_of_rent,
                description,
                start_date,
                end_date,
                amount,
                days_lived,
                due_date
            });
            return data;
        } catch (error) {}
    }
);

export const updateCharges = createAsyncThunk(
    'Charges/updateCharges',
    async ({
        id,
        unit_id,
        tenant_id,
        charge_category,
        charge_type,
        recurrence,
        percentage_of_rent,
        description,
        start_date,
        end_date,
        amount,
        days_lived,
        status,
        due_date
    }) => {
        try {
            const { data } = await api.patch(`/products/editUnitCharge/${id}`, {
                id,
                unit_id,
                tenant_id,
                charge_category,
                charge_type,
                recurrence,
                percentage_of_rent,
                description,
                start_date,
                end_date,
                amount,
                days_lived,
                status,
                due_date
            });
            return data;
        } catch (error) {}
    }
);
