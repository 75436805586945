import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getPayments = createAsyncThunk('Payment/getPaginatedPayment', async ({ reference_number }) => {
    try {
        let url = '';
        if (reference_number && page) {
            url = `/products/getPayment?ref_number=${reference_number}&page=${page}`;
        } else {
            url = `/products/getPayment?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getPaymentsNotPaginated = createAsyncThunk('Payment/getPayments', async () => {
    try {
        const { data } = await api.get(`/products/getPayment`);
        return data;
    } catch (error) {}
});
export const getPayment = createAsyncThunk('Payment/getPayment', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getPayment?id=${id}`);
        return data;
    } catch (error) {}
});

export const deletePayments = createAsyncThunk('Payment/deletePayment', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deletePayment/${id}`);
        return data;
    } catch (error) {}
});

export const addPayments = createAsyncThunk('Payment/addPayment', async ({ formData }) => {
    try {
        const { data } = await api.post(`/products/addPayment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updatePayments = createAsyncThunk(
    'Payment/updatePayment',
    async ({ id, payment_method, payment_type, amount, unit_id, details, status, approval_status }) => {
        try {
            const { data } = await api.patch(`/products/editPayment/${id}`, {
                id,
                payment_method,
                payment_type,
                amount,
                unit_id,
                details,
                approval_status,
                status
            });
            return data;
        } catch (error) {}
    }
);
