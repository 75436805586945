import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getMaintenances = createAsyncThunk('Maintenance/getPaginatedMaintenance', async ({ recurring, property_id }) => {
    try {
        let url = '';
        if (recurring) {
            url = `/products/getMaintenance?recurring=${recurring}`;
        } else if (property_id) {
            url = `/products/getMaintenance?property_id=${property_id}`;
        } else {
            url = `/products/getMaintenance`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getMaintenancesNotPaginated = createAsyncThunk('Maintenance/getMaintenances', async ({ property_id }) => {
    let url = '';
    if (property_id) {
        url = `/products/getMaintenance?property_id=${property_id}`;
    } else {
        url = `/products/getMaintenance`;
    }
    try {
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
export const getMaintenance = createAsyncThunk('Maintenance/getMaintenance', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getMaintenance?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteMaintenance = createAsyncThunk('Maintenance/deleteMaintenance', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteMaintenance/${id}`);
        return data;
    } catch (error) {}
});

export const addMaintenance = createAsyncThunk('Maintenance/addMaintenance', async ({ formData }) => {
    try {
        const { data } = await api.post(`/products/addMaintenance`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateMaintenance = createAsyncThunk(
    'Maintenance/updateMaintenance',
    async ({
        id,
        unit_id,
        tenant_id,
        title,
        description,
        priority,
        occurrence,
        occurrence_frequency,
        preferred_date_time_type,
        preferred_date_time,
        status,
        date_resolved,
        type
    }) => {
        try {
            const { data } = await api.patch(`/products/editMaintenance/${id}`, {
                id,
                unit_id,
                tenant_id,
                title,
                description,
                priority,
                occurrence,
                occurrence_frequency,
                preferred_date_time_type,
                preferred_date_time,
                status,
                type,
                date_resolved
            });
            return data;
        } catch (error) {}
    }
);
