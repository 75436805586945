import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getAdminAreas = createAsyncThunk('AdminArea/getPaginatedAdminArea', async ({ page, name, code, country_id, from, to }) => {
    try {
        let url = '';
        if (name && from && to) {
            url = `/config/getAdministrativeArea?name=${name}&from=${from}&to=${to}&page=${page}`;
        } else if (admin_area && country_name && from) {
            url = `/config/getAdministrativeArea?admin_area=${admin_area}&country_name=${country_name}&from=${from}&page=${page}`;
        } else if (code && name && to) {
            url = `/config/getAdministrativeArea?code=${code}&name=${name}&to=${to}&page=${page}`;
        } else if (country_id) {
            url = `/config/getAdministrativeArea?country_id=${country_id}&page=${page}`;
        } else {
            url = `/config/getAdministrativeArea?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getAdminAreasNotPaginated = createAsyncThunk('AdminArea/getAdminArea', async () => {
    try {
        const { data } = await api.get(`/config/getAdministrativeArea`);
        return data;
    } catch (error) {}
});
export const getAdminArea = createAsyncThunk('AdminArea/getSingleAdminArea', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getAdministrativeArea?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteAdminAreas = createAsyncThunk('AdminArea/deleteAdminArea', async ({ page, id }) => {
    try {
        await api.delete(`/config/deleteAdministrativeArea/${id}`);
        const { data } = await api.get(`/config/getAdministrativeArea?page=${page}`);
        return data;
    } catch (error) {}
});

export const addAdminAreas = createAsyncThunk('AdminArea/addAdminArea', async ({ country_id, name, code, status }) => {
    try {
        const { data } = await api.post(`/config/addAdministrativeArea`, {
            country_id,
            name,
            code,
            status
        });
        return data;
    } catch (error) {}
});

export const updateAdminAreas = createAsyncThunk('AdminArea/updateAdminArea', async ({ country_id, name, status, id }) => {
    try {
        const { data } = await api.patch(`/config/editAdministrativeArea/${id}`, {
            country_id,
            name,
            status,
            id
        });
        return data;
    } catch (error) {}
});
