/* eslint-disable no-unused-vars */
import { Box, Drawer, IconButton, List, ListItem, ListItemIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react'; // Import useState
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAuthUser } from 'store/features/auth/auth';
import { authLogout } from 'store/features/auth/authActions';

// material-ui icons
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MenuIcon from '@mui/icons-material/Menu';

// project imports
import LogoutIcon from '@mui/icons-material/Logout';
import LogoSection from '../MainLayout/LogoSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { isAuthenticated, userType } = useSelector(selectAuthUser);
    const dispatch = useDispatch();

    // State for managing drawer open/close
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Function to toggle drawer
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    // Function to close drawer
    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    // Logout
    const handleLogout = () => {
        dispatch(authLogout());
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}
        >
            {/* Logo Section */}
            <LogoSection />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Display links and icons on large screens */}
                {!isMobile && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isAuthenticated && (
                            <>
                                <Link
                                    to={userType === 'TENANT' ? '/tenant/dashboard' : userType === 'HOST' ? '/admin/dashboard' : '/'}
                                    style={{ textDecoration: 'none', marginRight: '20px' }}
                                >
                                    <Typography variant="h2" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                        Dashboard
                                    </Typography>
                                </Link>
                            </>
                        )}
                        <Link to="/marketplace" style={{ textDecoration: 'none', marginRight: '20px' }}>
                            <Typography variant="h2" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                Listings
                            </Typography>
                        </Link>
                        {!isAuthenticated ? (
                            <>
                                <Link to="/login" style={{ textDecoration: 'none', marginRight: '20px' }}>
                                    <Typography variant="h2" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                        Login
                                    </Typography>
                                </Link>
                                <Link to="/register" style={{ textDecoration: 'none', marginRight: '20px' }}>
                                    <Typography variant="h2" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                        Sign Up
                                    </Typography>
                                </Link>
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="h2"
                                    style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Typography>
                            </>
                        )}
                    </Box>
                )}

                {/* Burger menu for small screens */}
                {isMobile && (
                    <>
                        <IconButton onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer} sx={{ maxWidth: '80%', width: '100%' }}>
                            <List>
                                <ListItem>
                                    <IconButton onClick={closeDrawer} color="secondary" edge="end">
                                        <CloseIcon />
                                    </IconButton>
                                </ListItem>
                                {isAuthenticated && (
                                    <ListItem
                                        component={Link}
                                        to={userType === 'TENANT' ? '/tenant/dashboard' : userType === 'HOST' ? '/admin/dashboard' : '/'}
                                        onClick={closeDrawer}
                                        sx={{ fontSize: '30px' }}
                                    >
                                        <ListItemIcon sx={{ color: theme.palette.secondary.dark }}>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <Typography variant="h4" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                            Dashboard
                                        </Typography>
                                    </ListItem>
                                )}
                                <ListItem component={Link} to="/marketplace" onClick={closeDrawer} sx={{ fontSize: '30px' }}>
                                    <ListItemIcon sx={{ color: theme.palette.secondary.dark }}>
                                        <ManageSearchIcon />
                                    </ListItemIcon>
                                    <Typography variant="h4" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                        Listings
                                    </Typography>
                                </ListItem>
                                {!isAuthenticated ? (
                                    <>
                                        <ListItem component={Link} to="/login" onClick={closeDrawer} sx={{ fontSize: '30px' }}>
                                            <ListItemIcon sx={{ color: theme.palette.secondary.dark }}>
                                                <LoginIcon />
                                            </ListItemIcon>
                                            <Typography variant="h4" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                                Login
                                            </Typography>
                                        </ListItem>
                                        <ListItem component={Link} to="/register" onClick={closeDrawer} sx={{ fontSize: '30px' }}>
                                            <ListItemIcon sx={{ color: theme.palette.secondary.dark }}>
                                                <HowToRegIcon />
                                            </ListItemIcon>
                                            <Typography variant="h4" style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}>
                                                Sign Up
                                            </Typography>
                                        </ListItem>
                                    </>
                                ) : (
                                    <>
                                        <ListItem onClick={closeDrawer} sx={{ fontSize: '30px' }}>
                                            <ListItemIcon sx={{ color: theme.palette.secondary.dark }}>
                                                <LogoutIcon />
                                            </ListItemIcon>
                                            <Typography
                                                variant="h4"
                                                style={{ cursor: 'pointer', color: theme.palette.secondary.dark }}
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </Typography>
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </Drawer>
                    </>
                )}
            </Box>
        </Box>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
