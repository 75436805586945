import axios from 'axios';

export const API = process.env.REACT_APP_API_URL;
const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        source: 1
    },
    baseURL: API
});
export default api;
