import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getTenantShieldPluses = createAsyncThunk('TenantShieldPlus/getPaginatedTenantShieldPluses', async ({}) => {
    try {
        let url = `/shieldplus/getTenantShieldPlus?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getTenantShieldPlusNotPaginated = createAsyncThunk('TenantShieldPlus/getTenantShieldPlusNotPaginated', async ({ status }) => {
    try {
        let url = '';
        if (status) {
            url = `/shieldplus/getTenantShieldPlus?status=${status}`;
        } else {
            url = `/shieldplus/getTenantShieldPlus?status`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
export const getTenantShieldPlus = createAsyncThunk('TenantShieldPlus/getTenantShieldPlus', async ({ id }) => {
    try {
        const { data } = await api.get(`/shieldplus/getTenantShieldPlus?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteTenantShieldPlus = createAsyncThunk('TenantShieldPlus/deleteTenantShieldPlus', async ({ id }) => {
    try {
        const { data } = await api.delete(`/shieldplus/deleteTenantShieldPlus/${id}`);
        return data;
    } catch (error) {}
});

export const addTenantShieldPlus = createAsyncThunk(
    'TenantShieldPlus/addTenantShieldPlus',
    async ({
        additional_coverage,
        total_items_cost,
        policy_start_date,
        monthly_fee,
        payment_schedule,
        market_price,
        genius_price,
        unit_id,
        plan,
        address,
        amount_due_now,
        payer
    }) => {
        try {
            const { data } = await api.post(`/shieldplus/addTenantShieldPlus`, {
                additional_coverage,
                total_items_cost,
                policy_start_date,
                monthly_fee,
                payment_schedule,
                market_price,
                genius_price,
                address,
                plan,
                amount_due_now,
                unit_id,
                payer
            });
            return data;
        } catch (error) {}
    }
);

export const updateTenantShieldPlus = createAsyncThunk('TenantShieldPlus/updateTenantShieldPlus', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/shieldplus/editTenantShieldPlus/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
