import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getInventorys = createAsyncThunk('Inventorys/getPaginatedClaims', async ({}) => {
    try {
        let url = `/inventory/getInventory?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getInventoryNotPaginated = createAsyncThunk('Inventorys/getClaimsNotPaginated', async () => {
    try {
        const { data } = await api.get(`/inventory/getInventory`);
        return data;
    } catch (error) {}
});
export const getInventory = createAsyncThunk('Inventorys/getInventory', async ({ id }) => {
    try {
        const { data } = await api.get(`/inventory/getInventory?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteInventory = createAsyncThunk('Inventorys/deleteInventory', async ({ id }) => {
    try {
        const { data } = await api.delete(`/inventory/deleteInventory/${id}`);
        return data;
    } catch (error) {}
});

export const addInventory = createAsyncThunk('Inventorys/addInventory', async ({ formData }) => {
    try {
        const { data } = await api.post(`/inventory/addInventory`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateInventory = createAsyncThunk('Inventorys/updateInventory', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/inventory/editInventory/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
