import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getWorkOrders = createAsyncThunk('WorkOrder/getPaginatedWorkOrder', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/products/getWorkOrder?page=${page}`;
        } else {
            url = `/products/getWorkOrder`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getWorkOrdersNotPaginated = createAsyncThunk('WorkOrder/getWorkOrders', async () => {
    try {
        const { data } = await api.get(`/products/getWorkOrder`);
        return data;
    } catch (error) {}
});
export const getWorkOrder = createAsyncThunk('WorkOrder/getWorkOrder', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getWorkOrder?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteWorkOrder = createAsyncThunk('WorkOrder/deleteWorkOrder', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteWorkOrder/${id}`);
        return data;
    } catch (error) {}
});

export const addWorkOrder = createAsyncThunk('WorkOrder/addWorkOrder', async ({ request_id }) => {
    try {
        const { data } = await api.post(`/products/addWorkOrder`, { request_id });
        return data;
    } catch (error) {}
});

export const updateWorkOrder = createAsyncThunk(
    'WorkOrder/updateWorkOrder',
    async ({ id, request_id, work_order_number, assigned_to, status }) => {
        try {
            const { data } = await api.patch(`/products/editWorkOrder/${id}`, {
                id,
                request_id,
                work_order_number,
                assigned_to,
                status
            });
            return data;
        } catch (error) {}
    }
);
