import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getEntitys = createAsyncThunk('Entity/getPaginatedEntitys', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/config/getEntity?page=${page}`;
        } else {
            url = `/auth/getEntity`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getEntitysNotPaginated = createAsyncThunk('Entity/getEntitys', async () => {
    try {
        const { data } = await api.get(`/config/getEntity`);
        return data;
    } catch (error) {}
});
export const getEntity = createAsyncThunk('Entity/getEntity', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getEntity?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteEntity = createAsyncThunk('Entity/deleteEntity', async ({ id }) => {
    try {
        const { data } = await api.delete(`/config/deleteEntity/${id}`);
        return data;
    } catch (error) {}
});

export const addEntity = createAsyncThunk('Entity/addEntity', async ({ name, description }) => {
    try {
        const { data } = await api.post(`/config/addEntity`, { name, description });
        return data;
    } catch (error) {}
});

export const updateEntity = createAsyncThunk('Entity/updateEntity', async ({ id, name, description, status }) => {
    try {
        const { data } = await api.patch(`/config/editEntity/${id}`, {
            id,
            name,
            description,
            status
        });
        return data;
    } catch (error) {}
});
