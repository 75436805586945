import { createSlice } from '@reduxjs/toolkit';
import { reduxGetListings } from './listingsactions';

const initialState = {
    error: false,
    msg: undefined,
    data: {
        total_pages: undefined,
        total_items: undefined,
        items_per_page: undefined,
        current_page: undefined,
        current_page_items: undefined,
        items: []
    }
};

const ViewListingsSlice = createSlice({
    name: 'Listings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get listings
        builder.addCase(reduxGetListings.fulfilled, (state, { payload }) => {
            return {
                ...payload
            };
        });
    }
});

export default ViewListingsSlice.reducer;
