import { createSlice } from '@reduxjs/toolkit';
import { addRNPLs, deleteRNPLs, getRNPL, getRNPLs, getRNPLsNotPaginated, updateRNPLs } from './rnplActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    RNPLsLoading: false,
    RNPLSuccess: false,
    RNPLMsg: '',
    RNPLs: [],
    RNPL: undefined
};

const RNPL = createSlice({
    name: 'RNPL',
    initialState,
    reducers: {
        clearRNPLMessages: (state) => {
            state.error = false;
            state.RNPLMsg = '';
            state.RNPLSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRNPLs.pending, (state) => {
            state.RNPLsLoading = true;
        });
        builder.addCase(getRNPLs.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.RNPLs = action.payload?.data.items;
            state.error = action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
            state.RNPLSuccess = !action.payload?.error;
        });
        builder.addCase(getRNPL.pending, (state) => {
            state.RNPLsLoading = true;
        });
        builder.addCase(getRNPL.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.RNPL = action.payload?.data[0];
            state.error = action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
            state.RNPLSuccess = !action.payload?.error;
        });
        builder.addCase(getRNPLsNotPaginated.pending, (state) => {
            state.RNPLsLoading = true;
        });
        builder.addCase(getRNPLsNotPaginated.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.RNPLSuccess = !action.payload?.error;
            state.RNPLs = action.payload?.data;
            state.error = action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
        });
        builder.addCase(addRNPLs.pending, (state) => {
            state.RNPLsLoading = true;
        });
        builder.addCase(addRNPLs.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.error = action.payload?.error;
            state.RNPLSuccess = !action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
        });
        builder.addCase(updateRNPLs.pending, (state) => {
            state.RNPLsLoading = true;
        });
        builder.addCase(updateRNPLs.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.error = action.payload?.error;
            state.RNPLSuccess = !action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
        });
        builder.addCase(deleteRNPLs.fulfilled, (state, action) => {
            state.RNPLsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.RNPLs = action.payload?.data.items;
            state.error = action.payload?.error;
            state.RNPLMsg = action.payload?.msg;
            state.RNPLSuccess = !action.payload?.error;
        });
    }
});

export const { clearRNPLMessages } = RNPL.actions;

export default RNPL.reducer;
