import { createSlice } from '@reduxjs/toolkit';
import {
    addSettlementAccounts,
    getSettlementAccount,
    getSettlementAccountsNotPaginated,
    updateSettlementAccounts,
    deleteSettlementAccounts,
    getPaginatedSettlementAccount
} from './settlementAccountsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    SettlementAccountsLoading: false,
    SettlementAccountsSuccess: false,
    SettlementAccountsMsg: '',
    SettlementAccounts: []
};

const SettlementAccount = createSlice({
    name: 'SettlementAccount',
    initialState,
    reducers: {
        clearSettlementAccountMessages: (state) => {
            state.error = false;
            state.SettlementAccountsMsg = '';
            state.SettlementAccountsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPaginatedSettlementAccount.pending, (state) => {
            state.SettlementAccountsLoading = true;
        });
        builder.addCase(getPaginatedSettlementAccount.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.SettlementAccounts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.SettlementAccountsMsg = action.payload?.msg;
            state.SettlementAccountsSuccess = !action.payload?.error;
        });
        builder.addCase(getSettlementAccount.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.SettlementAccounts = action.payload?.data[0];
            state.error = action.payload?.error;
            state.SettlementAccountMsg = action.payload?.msg;
            state.SettlementAccountsSuccess = !action.payload?.error;
        });
        builder.addCase(getSettlementAccountsNotPaginated.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.SettlementAccountsSuccess = !action.payload?.error;
            state.SettlementAccounts = action.payload?.data;
            state.error = action.payload?.error;
            state.SettlementAccountsMsg = action.payload?.msg;
        });
        builder.addCase(addSettlementAccounts.pending, (state) => {
            state.SettlementAccountsLoading = true;
        });
        builder.addCase(addSettlementAccounts.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.error = action.payload?.error;
            state.SettlementAccountsSuccess = !action.payload?.error;
            state.SettlementAccountsMsg = action.payload?.msg;
        });
        builder.addCase(updateSettlementAccounts.pending, (state) => {
            state.SettlementAccountsLoading = true;
        });
        builder.addCase(updateSettlementAccounts.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.error = action.payload?.error;
            state.SettlementAccountsSuccess = !action.payload?.error;
            state.SettlementAccountMsg = action.payload?.msg;
        });
        builder.addCase(deleteSettlementAccounts.fulfilled, (state, action) => {
            state.SettlementAccountsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.SettlementAccounts = action.payload?.data.items;
            state.error = action.payload?.error;
            state.SettlementAccountsMsg = action.payload?.msg;
            state.SettlementAccountsSuccess = !action.payload?.error;
        });
    }
});

export const { clearSettlementAccountMessages } = SettlementAccount.actions;

export default SettlementAccount.reducer;
