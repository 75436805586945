import { createSlice } from '@reduxjs/toolkit';
import {
    addAdminAreas,
    deleteAdminAreas,
    getAdminArea,
    getAdminAreas,
    getAdminAreasNotPaginated,
    updateAdminAreas
} from './adminAreaActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    adminAreasLoading: false,
    adminAreasSuccess: false,
    adminAreaMsg: '',
    adminAreas: []
};

const AdminArea = createSlice({
    name: 'AdminArea',
    initialState,
    reducers: {
        clearAdminAreaMessages: (state) => {
            state.error = false;
            state.adminAreaMsg = '';
            state.adminAreasSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAdminAreas.pending, (state) => {
            state.adminAreasLoading = true;
        });
        builder.addCase(getAdminAreas.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.adminAreas = action.payload?.data.items;
            state.error = action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
        builder.addCase(getAdminArea.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.adminAreas = action.payload?.data[0];
            state.error = action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
        builder.addCase(getAdminAreasNotPaginated.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.adminAreas = action.payload?.data;
            state.error = action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
        builder.addCase(addAdminAreas.pending, (state) => {
            state.adminAreasLoading = true;
        });
        builder.addCase(addAdminAreas.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.error = action.payload?.error;
            state.adminAreasSuccess = !action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
        builder.addCase(updateAdminAreas.pending, (state) => {
            state.adminAreasLoading = true;
        });
        builder.addCase(updateAdminAreas.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.error = action.payload?.error;
            state.adminAreasSuccess = !action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
        builder.addCase(deleteAdminAreas.fulfilled, (state, action) => {
            state.adminAreasLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.adminAreas = action.payload?.data.items;
            state.error = action.payload?.error;
            state.adminAreaMsg = action.payload?.msg;
        });
    }
});

export const { clearAdminAreaMessages } = AdminArea.actions;

export default AdminArea.reducer;
