import admin from './admin-dashboard';
import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [admin, utilities]
};

export default menuItems;
