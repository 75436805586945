/* eslint-disable no-unused-vars */
// material-ui
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/features/auth/auth';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import tenantMenuItem from 'menu-items/tenants';
import adminMenuItem from 'menu-items/admin';
import { Ad } from 'tabler-icons-react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { userType } = useSelector(selectAuthUser);
    let menu = tenantMenuItem;
    if (userType === 'REALTOR' || userType === 'ADMIN') {
        menu = menuItem;
    } else if (userType === 'HOST') {
        menu = adminMenuItem;
    }

    const navItems = menu.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
