/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AuthGuard = Loadable(lazy(() => import('views/utilities/authGuard')));

// pages routing
const TenantDashboard = Loadable(lazy(() => import('views/dashboard/tenants')));
const MaintenancePage = Loadable(lazy(() => import('components/maintenance/tenantMaintenanceView')));
const TenantApplicationsPage = Loadable(lazy(() => import('views/pages/applications/tenantApplicationsView')));
const LeasePage = Loadable(lazy(() => import('views/pages/leasing/tenantLeaseView')));
const TenantsPayments = Loadable(lazy(() => import('components/accounting/tenantPaymentsIndex')));
const TenantRNPLView = Loadable(lazy(() => import('components/financing/index')));
const TenantSafeLeaseView = Loadable(lazy(() => import('components/financing/safeLeaseIndex')));
const RNPLForm = Loadable(lazy(() => import('components/financing/RNLPForm')));
const InviteApplicationsForm = Loadable(lazy(() => import('views/pages/applications/inviteApplicationsForm')));
const TenantCharges = Loadable(lazy(() => import('components/accounting/tenantCharges')));
const TenantUtilityBill = Loadable(lazy(() => import('components/accounting/tenantUtlities')));
const TenantMoveOutTable = Loadable(lazy(() => import('components/units/tenantMoveOutView')));
const TenantShieldPlusView = Loadable(lazy(() => import('components/shieldplus/tenantIndex')));
const TenantShieldPlusClaimsView = Loadable(lazy(() => import('components/shieldplus/shieldPlusClaimsIndex')));
const TenantShieldPlusForm = Loadable(lazy(() => import('components/shieldplus/tenantShieldPlusForm')));
const TenantShieldPlusDetailsPage = Loadable(lazy(() => import('components/shieldplus/tenantShieldPlusDetailsView')));
const ShieldPlusClaimForm = Loadable(lazy(() => import('components/shieldplus/shieldPlusClaimForm')));
const TenantClaimsDetailsPage = Loadable(lazy(() => import('components/shieldplus/shieldPlusClaimDetails')));
const TenantInvetoryViewPage = Loadable(lazy(() => import('components/inventory/tenantInventory')));
const InventoryDetailsPage = Loadable(lazy(() => import('components/inventory/inventoryDetails')));
const InventoryForm = Loadable(lazy(() => import('components/inventory/inventoryForm')));
const CreditBuildingDashboard = Loadable(lazy(() => import('components/credit-building/index')));
const MarketPlaceOffersPage = Loadable(lazy(() => import('components/offers/index')));
const TenantShieldPlusCustomForm = Loadable(lazy(() => import('components/shieldplus/tenantShieldPlusCustomForm')));

// ==============================|| MAIN ROUTING ||============================== //

const TenantRoutes = {
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'tenant/dashboard',
            element: <TenantDashboard />
        },
        {
            path: 'tenant/maintenance',
            element: <MaintenancePage />
        },
        {
            path: 'tenant/applications',
            element: <TenantApplicationsPage />
        },
        {
            path: 'tenant/leases',
            element: <LeasePage />
        },
        {
            path: 'tenant/payments',
            element: <TenantsPayments />
        },
        {
            path: 'tenant/rnpl',
            element: <TenantRNPLView />
        },
        {
            path: 'tenant/safelease',
            element: <TenantSafeLeaseView />
        },
        {
            path: 'tenant/rnpl/apply',
            element: <RNPLForm />
        },
        {
            path: 'tenant/applications/unit/:unit_id/apply',
            element: <InviteApplicationsForm />
        },
        {
            path: 'tenant/charges',
            element: <TenantCharges />
        },
        {
            path: 'tenant/utility-bills',
            element: <TenantUtilityBill />
        },
        {
            path: 'tenant/move-outs',
            element: <TenantMoveOutTable />
        },
        {
            path: 'tenant/shieldplus',
            element: <TenantShieldPlusView />
        },
        {
            path: 'tenant/shieldplus/:id',
            element: <TenantShieldPlusDetailsPage />
        },
        {
            path: 'tenant/claims',
            element: <TenantShieldPlusClaimsView />
        },
        {
            path: 'tenant/claims/new',
            element: <ShieldPlusClaimForm />
        },
        {
            path: 'tenant/claims/:id',
            element: <TenantClaimsDetailsPage />
        },
        {
            path: 'tenant/shieldplus/new',
            element: <TenantShieldPlusForm />
        },
        {
            path: 'tenant/shieldplus/custom',
            element: <TenantShieldPlusCustomForm />
        },
        {
            path: 'tenant/inventory',
            element: <TenantInvetoryViewPage />
        },
        {
            path: 'tenant/inventory/:id',
            element: <InventoryDetailsPage />
        },
        {
            path: 'tenant/inventory/new',
            element: <InventoryForm />
        },
        {
            path: 'tenant/credit-building',
            element: <CreditBuildingDashboard />
        },
        {
            path: 'tenant/offers',
            element: <MarketPlaceOffersPage />
        }
    ]
};

export default TenantRoutes;
