import { createSlice } from '@reduxjs/toolkit';
import { addCharges, deleteCharges, getCharge, getCharges, getChargesNotPaginated, updateCharges } from './chargeActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    ChargeLoading: false,
    ChargeSuccess: false,
    ChargeMsg: '',
    Charges: []
};

const Charge = createSlice({
    name: 'Charge',
    initialState,
    reducers: {
        clearChargeMessages: (state) => {
            state.error = false;
            state.ChargeMsg = '';
            state.ChargeSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCharges.pending, (state) => {
            state.ChargeLoading = true;
        });
        builder.addCase(getCharges.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Charges = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
            state.ChargeSuccess = !action.payload?.error;
        });
        builder.addCase(getCharge.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.Charges = action.payload?.data[0];
            state.error = action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
            state.ChargeSuccess = !action.payload?.error;
        });
        builder.addCase(getChargesNotPaginated.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.ChargeSuccess = !action.payload?.error;
            state.Charges = action.payload?.data;
            state.error = action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
        });
        builder.addCase(addCharges.pending, (state) => {
            state.ChargeLoading = true;
        });
        builder.addCase(addCharges.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.error = action.payload?.error;
            state.ChargeSuccess = !action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
        });
        builder.addCase(updateCharges.pending, (state) => {
            state.ChargeLoading = true;
        });
        builder.addCase(updateCharges.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.error = action.payload?.error;
            state.ChargeSuccess = !action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
        });
        builder.addCase(deleteCharges.fulfilled, (state, action) => {
            state.ChargeLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Charges = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ChargeMsg = action.payload?.msg;
            state.ChargeSuccess = !action.payload?.error;
        });
    }
});

export const { clearChargeMessages } = Charge.actions;

export default Charge.reducer;
