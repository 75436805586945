import { createSlice } from '@reduxjs/toolkit';
import { addQuotation, deleteQuotation, getQuotation, getQuotations, getQuotationsNotPaginated, updateQuotation } from './QuotationActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    QuotationsLoading: false,
    QuotationSuccess: false,
    QuotationMsg: '',
    Quotations: [],
    Quotation: null
};

const Quotation = createSlice({
    name: 'Quotation',
    initialState,
    reducers: {
        clearQuotationMessages: (state) => {
            state.error = false;
            state.QuotationMsg = '';
            state.QuotationSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getQuotations.pending, (state) => {
            state.QuotationsLoading = true;
        });
        builder.addCase(getQuotations.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Quotations = action.payload?.data;
            state.error = action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
            state.QuotationSuccess = !action.payload?.error;
        });
        builder.addCase(getQuotation.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.Quotation = action.payload?.data[0];
            state.error = action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
            state.QuotationSuccess = !action.payload?.error;
        });
        builder.addCase(getQuotationsNotPaginated.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.QuotationSuccess = !action.payload?.error;
            state.Quotations = action.payload?.data;
            state.error = action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
        });
        builder.addCase(addQuotation.pending, (state) => {
            state.QuotationsLoading = true;
        });
        builder.addCase(addQuotation.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.error = action.payload?.error;
            state.QuotationSuccess = !action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
        });
        builder.addCase(updateQuotation.pending, (state) => {
            state.QuotationsLoading = true;
        });
        builder.addCase(updateQuotation.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.error = action.payload?.error;
            state.QuotationSuccess = !action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
        });
        builder.addCase(deleteQuotation.fulfilled, (state, action) => {
            state.QuotationsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Quotations = action.payload?.data.items;
            state.error = action.payload?.error;
            state.QuotationMsg = action.payload?.msg;
            state.QuotationSuccess = !action.payload?.error;
        });
    }
});

export const { clearQuotationMessages } = Quotation.actions;

export default Quotation.reducer;
