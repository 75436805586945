import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getCampaigns = createAsyncThunk('Campaign/getPaginatedCampaign', async ({}) => {
    try {
        let url = `/campaigns/getCampaign?page=${page}`;

        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getCampaignsNotPaginated = createAsyncThunk('Campaign/getCampaigns', async () => {
    try {
        const { data } = await api.get(`/campaigns/getCampaign`);
        return data;
    } catch (error) {}
});

export const createCampaign = createAsyncThunk('Campaign/createCampaign', async (formData) => {
    try {
        let url = `/campaigns/addCampaign`;

        const response = await api.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});
