import { createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import api from './../../../api/api';

// login
export const authLogin = createAsyncThunk('login', async ({ username, password }) => {
    try {
        let user = { username, password };
        const { data } = await api.post('/account/login', user);
        return data;
    } catch (error) {}
});

// logout
export const authLogout = createAsyncThunk('logout', async () => {
    try {
        const { data } = await api.post('/account/logout');
        return data;
    } catch (error) {}
});

export const verifyUserAccount = createAsyncThunk(
    'verify',
    async ({ first_name, last_name, national_id, password, confirm_password, email, user_id, token }) => {
        try {
            const { data } = await api.post(`/account/verify`, {
                first_name,
                last_name,
                national_id,
                password,
                confirm_password,
                email,
                user_id,
                token
            });
            return data;
        } catch (error) {}
    }
);

export const verifyPasswordReset = createAsyncThunk('request-reset-password', async ({ username }) => {
    try {
        const { data } = await api.post(`/account/request-password-reset`, {
            username
        });
        return data;
    } catch (error) {}
});

export const ResetPassword = createAsyncThunk('reset-password', async ({ email, user_id, password, confirm_password, token }) => {
    try {
        const { data } = await api.post(`/account/password-reset`, {
            email,
            user_id,
            password,
            confirm_password,
            token
        });
        return data;
    } catch (error) {}
});

// load user profile
export const getProfile = createAsyncThunk('getProfile', async () => {
    try {
        const { data } = await api.get('/account/getProfile');
        return data;
    } catch (error) {}
});

export const decodeToken = (token) => {
    if (!token) return null;
    try {
        const decodedtoken = jwt_decode(token);
        return decodedtoken;
    } catch (error) {
        return null;
    }
};
