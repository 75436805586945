import { createSlice } from '@reduxjs/toolkit';
import { getCampaigns, getCampaignsNotPaginated, createCampaign } from './campaignsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    CampaignsLoading: false,
    CampaignsSuccess: false,
    CampaignMsg: '',
    Campaigns: [],
    Campaign: []
};

const Campaign = createSlice({
    name: 'Campaign',
    initialState,
    reducers: {
        clearCampaignMessages: (state) => {
            state.error = false;
            state.CampaignMsg = '';
            state.CampaignsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCampaigns.pending, (state) => {
            state.CampaignsLoading = true;
        });
        builder.addCase(getCampaigns.fulfilled, (state, action) => {
            state.CampaignsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Campaigns = action.payload?.data.items;
            state.error = action.payload?.error;
            state.CampaignMsg = action.payload?.msg;
            state.CampaignsSuccess = !action.payload?.error;
        });
        builder.addCase(getCampaignsNotPaginated.pending, (state) => {
            state.CampaignsLoading = true;
        });
        builder.addCase(getCampaignsNotPaginated.fulfilled, (state, action) => {
            state.CampaignsLoading = false;
            state.CampaignsSuccess = !action.payload?.error;
            state.Campaigns = action.payload?.data;
            state.error = action.payload?.error;
            state.CampaignMsg = action.payload?.msg;
        });
        builder.addCase(createCampaign.pending, (state) => {
            state.CampaignsLoading = true;
        });
        builder.addCase(createCampaign.fulfilled, (state, action) => {
            state.CampaignsLoading = false;
            state.CampaignsSuccess = !action.payload?.error;
            state.Campaign = action.payload?.data[0];
            state.error = action.payload?.error;
            state.CampaignMsg = action.payload?.msg;
        });
    }
});

export const { clearCampaignMessages } = Campaign.actions;

export default Campaign.reducer;
