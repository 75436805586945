import { createSlice } from '@reduxjs/toolkit';
import {
    addApplications,
    deleteApplications,
    getApplication,
    getApplications,
    getApplicationsNotPaginated,
    updateApplications,
    inviteTenant
} from './applicationActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    ApplicationsLoading: false,
    ApplicationsSuccess: false,
    ApplicationMsg: '',
    Applications: []
};

const Application = createSlice({
    name: 'Application',
    initialState,
    reducers: {
        clearApplicationMessages: (state) => {
            state.error = false;
            state.ApplicationMsg = '';
            state.ApplicationsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getApplications.pending, (state) => {
            state.ApplicationsLoading = true;
        });
        builder.addCase(getApplications.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Applications = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
            state.ApplicationsSuccess = !action.payload?.error;
        });
        builder.addCase(getApplication.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.Applications = action.payload?.data[0];
            state.error = action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
            state.ApplicationsSuccess = !action.payload?.error;
        });
        builder.addCase(getApplicationsNotPaginated.pending, (state) => {
            state.ApplicationsLoading = true;
        });
        builder.addCase(getApplicationsNotPaginated.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.ApplicationsSuccess = !action.payload?.error;
            state.Applications = action.payload?.data;
            state.error = action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
        });
        builder.addCase(addApplications.pending, (state) => {
            state.ApplicationsLoading = true;
        });
        builder.addCase(addApplications.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.error = action.payload?.error;
            state.ApplicationsSuccess = !action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
        });
        builder.addCase(updateApplications.pending, (state) => {
            state.ApplicationsLoading = true;
        });
        builder.addCase(updateApplications.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.error = action.payload?.error;
            state.ApplicationsSuccess = !action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
        });
        builder.addCase(deleteApplications.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Applications = action.payload?.data.items;
            state.error = action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
            state.ApplicationsSuccess = !action.payload?.error;
        });
        builder.addCase(inviteTenant.fulfilled, (state, action) => {
            state.ApplicationsLoading = false;
            state.error = action.payload?.error;
            state.ApplicationsSuccess = !action.payload?.error;
            state.ApplicationMsg = action.payload?.msg;
        });
    }
});

export const { clearApplicationMessages } = Application.actions;

export default Application.reducer;
