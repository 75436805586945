import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getEmployments = createAsyncThunk('Role/getPaginatedEmployments', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/account/getEmployment?page=${page}`;
        } else {
            url = `/account/getEmployment`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getEmploymentsNotPaginated = createAsyncThunk('Employment/getEmployments', async ({ tenant_id }) => {
    try {
        const { data } = await api.get(`/account/getEmployment/?tenant_id=${tenant_id}`);
        return data;
    } catch (error) {}
});
export const getEmployment = createAsyncThunk('Employment/getEmployment', async ({ id }) => {
    try {
        const { data } = await api.get(`/account/getEmployment?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteEmployment = createAsyncThunk('Employment/deleteEmployment', async ({ id }) => {
    try {
        const { data } = await api.delete(`/account/deleteEmployment/${id}`);
        return data;
    } catch (error) {}
});

export const addEmployment = createAsyncThunk(
    'Employment/addEmployment',
    async ({ current_or_recent_employer, monthly_income, job_title, employer_address, employment_status }) => {
        try {
            const { data } = await api.post(`/account/addEmployment`, {
                current_or_recent_employer,
                monthly_income,
                job_title,
                employer_address,
                employment_status
            });
            return data;
        } catch (error) {}
    }
);

export const updateEmployment = createAsyncThunk(
    'Employment/updateEmployment',
    async ({ id, current_or_recent_employer, monthly_income, job_title, employer_address, employment_status }) => {
        try {
            const { data } = await api.patch(`/account/editEmployment/${id}`, {
                id,
                current_or_recent_employer,
                monthly_income,
                job_title,
                employer_address,
                employment_status
            });
            return data;
        } catch (error) {}
    }
);
