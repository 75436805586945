import { createSlice } from '@reduxjs/toolkit';
import {
    addProperties,
    deleteProperties,
    getProperties,
    getProperty,
    getNotPaginatedProperties,
    updateProperties
} from './propertyActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    propertySuccess: false,
    propertiesMsg: '',
    properties: [],
    property: undefined,
    propertiesLoading: false,
    property_id: null
};
const Properties = createSlice({
    name: 'Properties',
    initialState,
    reducers: {
        clearPropertiesMessages: (state) => {
            state.error = false;
            state.propertiesMsg = '';
            state.propertySuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProperties.pending, (state) => {
            state.propertiesLoading = true;
        });
        builder.addCase(getProperties.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.properties = action.payload?.data.items;
            state.error = action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
            state.propertySuccess = !action.payload?.error;
        });
        builder.addCase(getNotPaginatedProperties.pending, (state) => {
            state.propertiesLoading = true;
        });
        builder.addCase(getNotPaginatedProperties.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.properties = action.payload?.data;
            state.error = action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
            state.propertySuccess = !action.payload?.error;
        });

        builder.addCase(addProperties.pending, (state) => {
            state.propertiesLoading = true;
        });
        builder.addCase(addProperties.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.error = action.payload?.error;
            state.propertySuccess = !action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
            state.property_id = action.payload?.data?.id;
        });
        builder.addCase(updateProperties.pending, (state) => {
            state.propertiesLoading = true;
        });
        builder.addCase(updateProperties.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.error = action.payload?.error;
            state.propertySuccess = !action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
        });
        builder.addCase(deleteProperties.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.properties = action.payload?.data.items;
            state.error = action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
            state.propertySuccess = !action.payload?.error;
        });
        builder.addCase(getProperty.fulfilled, (state, action) => {
            state.propertiesLoading = false;
            state.property = action.payload?.data[0];
            state.error = action.payload?.error;
            state.propertiesMsg = action.payload?.msg;
            state.propertySuccess = !action.payload?.error;
        });
    }
});

export const { clearPropertiesMessages } = Properties.actions;

export default Properties.reducer;
