import DOMPurify from 'dompurify';

export const createMarkup = (html) => {
    return {
        __html: DOMPurify.sanitize(html)
    };
};

export const hasPermission = (actions, userActions, action) => {
    let actionID = actions.filter((item) => item.name === action)[0].id;
    let userActionSet = new Set(userActions);
    return userActionSet.has(actionID);
};
