import { createSlice } from '@reduxjs/toolkit';
import { addUser, deleteUser, getUser, getUsers, getUsersNotPaginated, updateUser } from './userActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    UsersLoading: false,
    UserSuccess: false,
    UserMsg: '',
    Users: [],
    User: null
};

const User = createSlice({
    name: 'User',
    initialState,
    reducers: {
        clearUserMessages: (state) => {
            state.error = false;
            state.UserMsg = '';
            state.UserSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.pending, (state) => {
            state.UsersLoading = true;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Users = action.payload?.data;
            state.error = action.payload?.error;
            state.UserMsg = action.payload?.msg;
            state.UserSuccess = !action.payload?.error;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.User = action.payload?.data[0];
            state.error = action.payload?.error;
            state.UserMsg = action.payload?.msg;
            state.UserSuccess = !action.payload?.error;
        });
        builder.addCase(getUsersNotPaginated.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.UserSuccess = !action.payload?.error;
            state.Users = action.payload?.data;
            state.error = action.payload?.error;
            state.UserMsg = action.payload?.msg;
        });
        builder.addCase(addUser.pending, (state) => {
            state.UsersLoading = true;
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.error = action.payload?.error;
            state.UserSuccess = !action.payload?.error;
            state.UserMsg = action.payload?.msg;
        });
        builder.addCase(updateUser.pending, (state) => {
            state.UsersLoading = true;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.error = action.payload?.error;
            state.UserSuccess = !action.payload?.error;
            state.UserMsg = action.payload?.msg;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.UsersLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Users = action.payload?.data.items;
            state.error = action.payload?.error;
            state.UserMsg = action.payload?.msg;
            state.UserSuccess = !action.payload?.error;
        });
    }
});

export const { clearUserMessages } = User.actions;

export default User.reducer;
