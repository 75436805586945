import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getLandlordShieldPluses = createAsyncThunk('LandlordShieldPlus/getPaginatedLandlordShieldPluses', async ({}) => {
    try {
        let url = `/shieldplus/getLandlordShieldPlus?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getLandlordShieldPlusNotPaginated = createAsyncThunk(
    'LandlordShieldPlus/getLandlordShieldPlusNotPaginated',
    async ({ status }) => {
        try {
            let url = '';
            if (status) {
                url = `/shieldplus/getLandlordShieldPlus?status=${status}`;
            } else {
                url = `/shieldplus/getLandlordShieldPlus?status`;
            }
            const { data } = await api.get(url);
            return data;
        } catch (error) {}
    }
);
export const getLandlordShieldPlus = createAsyncThunk('LandlordShieldPlus/getLandlordShieldPlus', async ({ id }) => {
    try {
        const { data } = await api.get(`/shieldplus/getLandlordShieldPlus?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteLandlordShieldPlus = createAsyncThunk('LandlordShieldPlus/deleteLandlordShieldPlus', async ({ id }) => {
    try {
        const { data } = await api.delete(`/shieldplus/deleteLandlordShieldPlus/${id}`);
        return data;
    } catch (error) {}
});

export const addLandlordShieldPlus = createAsyncThunk(
    'LandlordShieldPlus/addLandlordShieldPlus',
    async ({
        add_ons,
        value,
        policy_start_date,
        monthly_fee,
        payment_schedule,
        market_price,
        genius_price,
        address,
        amount_due_now,
        property_id,
        year_built,
        payer,
        size,
        property_type
    }) => {
        try {
            const { data } = await api.post(`/shieldplus/addLandlordShieldPlus`, {
                add_ons,
                value,
                policy_start_date,
                monthly_fee,
                payment_schedule,
                market_price,
                genius_price,
                address,
                amount_due_now,
                property_id,
                year_built,
                payer,
                size,
                property_type
            });
            return data;
        } catch (error) {}
    }
);

export const updateLandlordShieldPlus = createAsyncThunk('LandlordShieldPlus/updateLandlordShieldPlus', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/shieldplus/editLandlordShieldPlus/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
