import { createSlice } from '@reduxjs/toolkit';
import {
    addTenants,
    deleteTenants,
    getTenant,
    getTenants,
    getTenantsNotPaginated,
    updateTenants,
    getTenantsHistoryNotPaginated
} from './tenantActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    TenantsLoading: false,
    TenantsSuccess: false,
    TenantMsg: '',
    Tenants: [],
    Tenant: [],
    TenantsHistory: []
};

const Tenant = createSlice({
    name: 'Tenant',
    initialState,
    reducers: {
        clearTenantMessages: (state) => {
            state.error = false;
            state.TenantMsg = '';
            state.TenantsSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTenants.pending, (state) => {
            state.TenantsLoading = true;
        });
        builder.addCase(getTenants.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Tenants = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantMsg = action.payload?.msg;
            state.TenantsSuccess = !action.payload?.error;
        });
        builder.addCase(getTenant.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.Tenant = action.payload?.data[0];
            state.error = action.payload?.error;
            state.TenantMsg = action.payload?.msg;
            state.TenantsSuccess = !action.payload?.error;
        });
        builder.addCase(getTenantsNotPaginated.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.TenantsSuccess = !action.payload?.error;
            state.Tenants = action.payload?.data;
            state.error = action.payload?.error;
            state.TenantMsg = action.payload?.msg;
        });
        builder.addCase(addTenants.pending, (state) => {
            state.TenantsLoading = true;
        });
        builder.addCase(addTenants.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.error = action.payload?.error;
            state.TenantsSuccess = !action.payload?.error;
            state.TenantMsg = action.payload?.msg;
        });
        builder.addCase(updateTenants.pending, (state) => {
            state.TenantsLoading = true;
        });
        builder.addCase(updateTenants.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.error = action.payload?.error;
            state.TenantsSuccess = !action.payload?.error;
            state.TenantMsg = action.payload?.msg;
        });
        builder.addCase(deleteTenants.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Tenants = action.payload?.data.items;
            state.error = action.payload?.error;
            state.TenantMsg = action.payload?.msg;
            state.TenantsSuccess = !action.payload?.error;
        });
        builder.addCase(getTenantsHistoryNotPaginated.fulfilled, (state, action) => {
            state.TenantsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.TenantsHistory = action.payload?.data;
            state.error = action.payload?.error;
            state.TenantMsg = action.payload?.msg;
            state.TenantsSuccess = !action.payload?.error;
        });
    }
});

export const { clearTenantMessages } = Tenant.actions;

export default Tenant.reducer;
