import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getBanksList = createAsyncThunk(
    'ListBanks/getBanksList',

    async () => {
        try {
            let url = '/payments/utils/listBanks';
            const { data } = await api.get(url);
            return data;
        } catch (error) {}
    }
);
