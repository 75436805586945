import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getUnitPrefences = createAsyncThunk('UnitPrefence/getPaginatedUnitPrefences', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/account/getUnitPrefence?page=${page}`;
        } else {
            url = `/account/getUnitPrefence`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

export const getUnitPrefencesNotPaginated = createAsyncThunk('UnitPrefence/getUnitPrefences', async () => {
    try {
        const { data } = await api.get(`/account/getUnitPrefence`);
        return data;
    } catch (error) {}
});
export const getUnitPrefence = createAsyncThunk('UnitPrefence/getUnitPrefence', async ({ id }) => {
    try {
        const { data } = await api.get(`/account/getUnitPrefence?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteUnitPrefence = createAsyncThunk('UnitPrefence/deleteUnitPrefence', async ({ id }) => {
    try {
        const { data } = await api.delete(`/account/deleteUnitPrefence/${id}`);
        return data;
    } catch (error) {}
});

export const addUnitPrefence = createAsyncThunk(
    'UnitPrefence/addUnitPrefence',
    async ({ unit_type, locations, beds, baths, min_price, max_price, pets_allowed, buy_or_rent }) => {
        try {
            const { data } = await api.post(`/account/addUnitPrefence`, {
                unit_type,
                locations,
                beds,
                baths,
                min_price,
                max_price,
                pets_allowed,
                buy_or_rent
            });
            return data;
        } catch (error) {}
    }
);

export const updateUnitPrefence = createAsyncThunk(
    'UnitPrefence/updateUnitPrefence',
    async ({ id, unit_type, locations, beds, baths, min_price, max_price, pets_allowed, buy_or_rent }) => {
        try {
            const { data } = await api.patch(`/account/editUnitPrefence/${id}`, {
                id,
                unit_type,
                locations,
                beds,
                baths,
                min_price,
                max_price,
                pets_allowed,
                buy_or_rent
            });
            return data;
        } catch (error) {}
    }
);
