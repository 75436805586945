import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { verifyPasswordReset } from 'store/features/auth/authActions';
import { clearVerificationMessages } from 'store/features/auth/authSlice';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import SnackBarAlert from 'utils/snackBarAlert';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const RequestResetPassword = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { msg, VerificationSuccess } = useSelector((store) => store.auth);
    const scriptedRef = useScriptRef();
    const dispatch = useDispatch();
    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertClose = () => {
        dispatch(clearVerificationMessages());
        setAlertOpen(false);
        if (VerificationSuccess == true) {
            navigate(`/login`, { replace: true });
        }
    };
    //verify
    const handleVerify = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        dispatch(
            verifyPasswordReset({
                username: formData.get('username')?.toString()
            })
        );
        setAlertOpen(true);
    };

    return (
        <>
            {VerificationSuccess ? (
                <SnackBarAlert
                    alertOpen={alertOpen}
                    handleAlertClose={handleAlertClose}
                    height="30%"
                    setAlertOpen={setAlertOpen}
                    alertMessage={msg}
                    severity="success"
                />
            ) : (
                <SnackBarAlert
                    alertOpen={alertOpen}
                    handleAlertClose={handleAlertClose}
                    height="30%"
                    setAlertOpen={setAlertOpen}
                    alertMessage={msg}
                    severity="error"
                />
            )}
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            Forgot Password?
                        </Typography>
                        <Typography variant="subtitle3">
                            Don't worry,just enter your email or phone number you registered with and we will email you a password reset
                            link.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    username: ''
                }}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, isSubmitting, values }) => (
                    <form noValidate onSubmit={handleVerify} {...others}>
                        <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">Email/ Phone Number</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="text"
                                value={values.username}
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                        </FormControl>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Reset My Password
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RequestResetPassword;
