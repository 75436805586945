import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getQuotations = createAsyncThunk('Quotation/getPaginatedQuotation', async ({ page }) => {
    try {
        let url = '';
        if (page) {
            url = `/products/getQuotation?page=${page}`;
        } else {
            url = `/products/getQuotation`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getQuotationsNotPaginated = createAsyncThunk('Quotation/getQuotations', async () => {
    try {
        const { data } = await api.get(`/products/getQuotation`);
        return data;
    } catch (error) {}
});
export const getQuotation = createAsyncThunk('Quotation/getQuotation', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getQuotation?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteQuotation = createAsyncThunk('Quotation/deleteQuotation', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteQuotation/${id}`);
        return data;
    } catch (error) {}
});

export const addQuotation = createAsyncThunk(
    'Quotation/addQuotation',
    async ({ request_id, service_pro_id, labour_cost, material_cost, status }) => {
        try {
            const { data } = await api.post(`/products/addQuotation`, {
                request_id,
                service_pro_id,
                labour_cost,
                material_cost,
                status
            });
            return data;
        } catch (error) {}
    }
);

export const updateQuotation = createAsyncThunk(
    'Quotation/updateQuotation',
    async ({ id, request_id, service_pro_id, labour_cost, material_cost, total_amount, status }) => {
        try {
            const { data } = await api.patch(`/products/editQuotation/${id}`, {
                id,
                request_id,
                service_pro_id,
                labour_cost,
                material_cost,
                total_amount,
                status
            });
            return data;
        } catch (error) {}
    }
);
