import { createSlice } from '@reduxjs/toolkit';
import { addInventory, getInventory, deleteInventory, getInventorys, getInventoryNotPaginated, updateInventory } from './inventoryActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    InventoryLoading: false,
    InventorySuccess: false,
    InventoryMsg: '',
    Inventorys: [],
    Inventory: undefined
};

const Inventory = createSlice({
    name: 'Inventory',
    initialState,
    reducers: {
        clearInventoryMessages: (state) => {
            state.error = false;
            state.InventoryMsg = '';
            state.InventorySuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInventorys.pending, (state) => {
            state.InventoryLoading = true;
        });
        builder.addCase(getInventorys.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Inventorys = action.payload?.data.items;
            state.error = action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
            state.InventorySuccess = !action.payload?.error;
        });
        builder.addCase(getInventory.pending, (state) => {
            state.InventoryLoading = true;
        });
        builder.addCase(getInventory.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.Inventory = action.payload?.data[0];
            state.error = action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
            state.InventorySuccess = !action.payload?.error;
        });
        builder.addCase(getInventoryNotPaginated.pending, (state) => {
            state.InventoryLoading = true;
        });
        builder.addCase(getInventoryNotPaginated.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.InventorySuccess = !action.payload?.error;
            state.Inventorys = action.payload?.data;
            state.error = action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
        });
        builder.addCase(addInventory.pending, (state) => {
            state.InventoryLoading = true;
        });
        builder.addCase(addInventory.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.error = action.payload?.error;
            state.InventorySuccess = !action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
        });
        builder.addCase(updateInventory.pending, (state) => {
            state.InventoryLoading = true;
        });
        builder.addCase(updateInventory.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.error = action.payload?.error;
            state.InventorySuccess = !action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
        });
        builder.addCase(deleteInventory.fulfilled, (state, action) => {
            state.InventoryLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Inventorys = action.payload?.data.items;
            state.error = action.payload?.error;
            state.InventoryMsg = action.payload?.msg;
            state.InventorySuccess = !action.payload?.error;
        });
    }
});

export const { clearInventoryMessages } = Inventory.actions;

export default Inventory.reducer;
