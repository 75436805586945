import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getPaginatedSettlementAccount = createAsyncThunk(
    'SettlementAccount/getPaginatedSettlementAccount',

    async ({ name, client_id, page }) => {
        try {
            let url = '';
            if (client_id && page) {
                url = `/payments/getSettlementAccount?client_id=${client_id}&page=${page}`;
            } else if (name) {
                url = `/payments/getSettlementAccount?name=${name}`;
            } else {
                url = `/payments/getSettlementAccount?page=${page}`;
            }
            const { data } = await api.get(url);
            return data;
        } catch (error) {}
    }
);

//not paginated
export const getSettlementAccountsNotPaginated = createAsyncThunk(
    'SettlementAccount/getSettlementAccountsNotPaginated',
    async ({ client_id }) => {
        let url = '';
        if (client_id) {
            url = `/payments/getSettlementAccount?client_id=${client_id}`;
        } else {
            url = `/payments/getSettlementAccount`;
        }
        try {
            const { data } = await api.get(url);
            return data;
        } catch (error) {}
    }
);

export const getSettlementAccount = createAsyncThunk('SettlementAccount/getSettlementAccount', async ({ id }) => {
    try {
        const { data } = await api.get(`/payments/getSettlementAccount?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteSettlementAccounts = createAsyncThunk('SettlementAccount/deleteSettlementAccount', async ({ id }) => {
    try {
        const { data } = await api.delete(`/payments/deleteSettlementAccount/${id}`);
        return data;
    } catch (error) {}
});

export const addSettlementAccounts = createAsyncThunk(
    'SettlementAccount/addSettlementAccount',
    async ({ bank, account_number, account_name, property_id }) => {
        try {
            const { data } = await api.post(`/payments/addSettlementAccount`, {
                bank,
                account_number,
                account_name,
                property_id
            });
            return data;
        } catch (error) {}
    }
);

export const updateSettlementAccounts = createAsyncThunk(
    'SettlementAccount/updateSettlementAccount',
    async ({ id, bank, account_name, account_number, property_id }) => {
        try {
            const { data } = await api.patch(`/payments/editSettlementAccount/${id}`, {
                id,
                bank,
                account_number,
                account_name,
                property_id
            });
            return data;
        } catch (error) {}
    }
);
