import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// reducer import
import customizationReducer from './customizationReducer';
import accountsReducer from './features/auth/accountsSlice';
import authReducer from './features/auth/authSlice';
import RoleReducer from './features/auth/rolesSlice';
import UserReducer from './features/auth/userSlice';
import messagesReducer from './features/communications/messagesSlice';
import notificationsReducer from './features/communications/notificationsSlice';
import viewListingsReducer from './features/listings/listingsslice';
import propertyReducer from './features/property/propertySlice';
import unitsReducer from './features/property/unitSlice';
import adminAreaReducer from './masterdata/adminArea/adminAreaSlice';
import propertyTypeReducer from './masterdata/configs/propertyTypeSlice';
import unitTypeReducer from './masterdata/configs/unitTypeSlice';
import TenantReducer from './masterdata/people/tenant/tenantSlice';
import PaymentsReducer from './masterdata/products/accounting/paymentsSlice';
import ApplicationReducer from './masterdata/products/applications/applicationSlice';
import LeaseReducer from './masterdata/products/lease/leaseSlice';
import maintenanceReducer from './masterdata/products/maintenance/maintenanceSlice';
import { default as listReducer } from './masterdata/products/marketing/listingsSlice';
import quotationReducer from './masterdata/products/quotations/QuotationSlice';
import WorkOrderReducer from './masterdata/products/quotations/WorkOrderSlice';
import BanksListReducer from './payments/listBanksSlice';
import SettlementAccountReducer from './payments/settlementAccountsSlice';
import RNPLReducer from './rnpl/rnplSlice';
import EmploymentReducer from './features/auth/employmentSlice';
import NextKinReducer from './features/auth/nextKinSlice';
import chargeReducer from './masterdata/products/accounting/chargeSlice.js';
import UtilityBillsReducer from './masterdata/products/accounting/utilitySlice';
import UserUnitPreferenceReducer from './features/auth/userUnitPreferenceSlice';
import CampaignReducer from './masterdata/products/marketing/campaignsSlice';
import contactRducer from './masterdata/people/contactsSlice';
import MoveOutReducer from './features/property/moveOutSlice';
import SafeLeaseReducer from './rnpl/safeLeaseSlice';
import TenantShieldPlusReducer from './shieldplus/tenantShieldPlusSlice';
import TenantClaimsReducer from './shieldplus/tenantClaimsSlice';
import InventoryReducer from './inventory/inventorySlice';
import LandlordShieldPlusReducer from './shieldplus/landlordShieldPluSlice';
import LandlordClaimsReducer from './shieldplus/landlordShieldPluClaimsSlice';
import EntityReducer from './masterdata/configs/entitySlice';

// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    properties: propertyReducer,
    adminAreas: adminAreaReducer,
    propertyTypes: propertyTypeReducer,
    accounts: accountsReducer,
    units: unitsReducer,
    unitTypes: unitTypeReducer,
    tenants: TenantReducer,
    applications: ApplicationReducer,
    leases: LeaseReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    listings: listReducer,
    maintenances: maintenanceReducer,
    quotations: quotationReducer,
    payments: PaymentsReducer,
    roles: RoleReducer,
    users: UserReducer,
    workorders: WorkOrderReducer,
    settlementsAccounts: SettlementAccountReducer,
    banks: BanksListReducer,
    view_listings: viewListingsReducer,
    rnpls: RNPLReducer,
    employment: EmploymentReducer,
    kin: NextKinReducer,
    charges: chargeReducer,
    utitility_bills: UtilityBillsReducer,
    unit_preferences: UserUnitPreferenceReducer,
    campaigns: CampaignReducer,
    contacts: contactRducer,
    move_outs: MoveOutReducer,
    safe_lease: SafeLeaseReducer,
    shieldplus: TenantShieldPlusReducer,
    tenant_claims: TenantClaimsReducer,
    inventory: InventoryReducer,
    landlord_shieldplus: LandlordShieldPlusReducer,
    landlord_claims: LandlordClaimsReducer,
    entity: EntityReducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'appConfig']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});
export const persistor = persistStore(store);
