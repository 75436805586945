import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getTenants = createAsyncThunk('Tenant/getPaginatedTenant', async ({ page, last_name, first_name, phone, email }) => {
    try {
        let url = '';
        if (first_name && page) {
            url = `/account/getTenant?fisrt_name=${first_name}&page=${page}`;
        } else if (email) {
            url = `/account/getTenant?email=${email}`;
        } else if (phone) {
            url = `/account/getTenant?phone=${phone}`;
        } else if (last_name && page) {
            url = `/account/getTenant?last_name=${last_name}&page=${page}`;
        } else {
            url = `/account/getTenant?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getTenantsNotPaginated = createAsyncThunk('Tenant/getTenants', async ({ property_id, get_entity_tenants }) => {
    let url = '';
    if (property_id) {
        url = `/account/getTenant?property_id=${property_id}`;
    } else if (get_entity_tenants) {
        url = `/account/getTenant?get_entity_tenants=${get_entity_tenants}`;
    } else {
        url = `/account/getTenant`;
    }
    try {
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
export const getTenant = createAsyncThunk('Tenant/getTenant', async ({ id }) => {
    try {
        const { data } = await api.get(`/account/getTenant?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteTenants = createAsyncThunk('Tenant/deleteTenant', async ({ id }) => {
    try {
        const { data } = await api.delete(`/account/deleteTenant/${id}`);
        return data;
    } catch (error) {}
});

export const addTenants = createAsyncThunk('Tenant/addTenant', async ({ first_name, last_name, email, phone, unit_id, role }) => {
    try {
        const { data } = await api.post(`/account/addTenant`, {
            first_name,
            last_name,
            email,
            phone,
            unit_id,
            role
        });
        return data;
    } catch (error) {}
});

export const updateTenants = createAsyncThunk('Tenant/updateTenant', async ({ id, first_name, last_name, email, phone }) => {
    try {
        const { data } = await api.patch(`/account/editTenant/${id}`, {
            id,
            first_name,
            last_name,
            email,
            phone
        });
        return data;
    } catch (error) {}
});

export const getTenantsHistoryNotPaginated = createAsyncThunk('Tenant/getTenantsHistory', async ({}) => {
    try {
        let url = 'tenants/getTenantHistory';
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
