import { createSlice } from '@reduxjs/toolkit';
import { addWorkOrder, deleteWorkOrder, getWorkOrder, getWorkOrders, getWorkOrdersNotPaginated, updateWorkOrder } from './WorkOrderActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    WorkOrdersLoading: false,
    WorkOrderSuccess: false,
    WorkOrderMsg: '',
    WorkOrders: [],
    WorkOrder: null
};

const WorkOrder = createSlice({
    name: 'WorkOrder',
    initialState,
    reducers: {
        clearWorkOrderMessages: (state) => {
            state.error = false;
            state.WorkOrderMsg = '';
            state.WorkOrderSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getWorkOrders.pending, (state) => {
            state.WorkOrdersLoading = true;
        });
        builder.addCase(getWorkOrders.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.WorkOrders = action.payload?.data;
            state.error = action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
            state.WorkOrderSuccess = !action.payload?.error;
        });
        builder.addCase(getWorkOrder.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.WorkOrder = action.payload?.data[0];
            state.error = action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
            state.WorkOrderSuccess = !action.payload?.error;
        });
        builder.addCase(getWorkOrdersNotPaginated.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.WorkOrderSuccess = !action.payload?.error;
            state.WorkOrders = action.payload?.data;
            state.error = action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
        });
        builder.addCase(addWorkOrder.pending, (state) => {
            state.WorkOrdersLoading = true;
        });
        builder.addCase(addWorkOrder.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.error = action.payload?.error;
            state.WorkOrderSuccess = !action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
        });
        builder.addCase(updateWorkOrder.pending, (state) => {
            state.WorkOrdersLoading = true;
        });
        builder.addCase(updateWorkOrder.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.error = action.payload?.error;
            state.WorkOrderSuccess = !action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
        });
        builder.addCase(deleteWorkOrder.fulfilled, (state, action) => {
            state.WorkOrdersLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.WorkOrders = action.payload?.data.items;
            state.error = action.payload?.error;
            state.WorkOrderMsg = action.payload?.msg;
            state.WorkOrderSuccess = !action.payload?.error;
        });
    }
});

export const { clearWorkOrderMessages } = WorkOrder.actions;

export default WorkOrder.reducer;
