import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getNotPaginatedProperties = createAsyncThunk('properties/getPropertiesWithoutPagination', async () => {
    try {
        const { data } = await api.get(`/config/getProperty`);
        return data;
    } catch (error) {}
});

export const getProperties = createAsyncThunk('properties/getProperties', async ({ page, property_name, from, to }) => {
    try {
        let data = api.get('/config/getProperty', { params: { page, property_name, from, to } });
        return data;
    } catch (error) {}
});

export const getProperty = createAsyncThunk('properties/getProperty', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getProperty?id=${id}`);
        return data;
    } catch (error) {}
});

export const addProperties = createAsyncThunk(
    'properties/addProperties',
    async ({
        name,
        admin_area_id,
        land_registration_number,
        location,
        no_of_units,
        property_type_id,
        owner_id,
        longitude,
        latitude,
        units,
        service_charges
    }) => {
        try {
            const { data } = await api.post(`/config/addProperty`, {
                name,
                land_registration_number,
                admin_area_id,
                location,
                no_of_units,
                property_type_id,
                owner_id,
                longitude,
                latitude,
                units,
                service_charges
            });
            return data;
        } catch (error) {}
    }
);

export const updateProperties = createAsyncThunk(
    'properties/updateProperties',
    async ({
        id,
        name,
        land_registration_number,
        admin_area_id,
        location,
        no_of_units,
        property_type_id,
        owner_id,
        longitude,
        latitude,
        units,
        service_charges
    }) => {
        try {
            const { data } = await api.patch(`/config/editProperty/${id}`, {
                id,
                name,
                land_registration_number,
                admin_area_id,
                location,
                no_of_units,
                property_type_id,
                owner_id,
                longitude,
                latitude,
                units,
                service_charges
            });
            return data;
        } catch (error) {}
    }
);

export const deleteProperties = createAsyncThunk('properties/deleteProperties', async ({ page, id }) => {
    try {
        await api.delete(`/config/deleteProperty/${id}`);
        const { data } = await api.get(`/config/getProperty?page=${page}`);
        return data;
    } catch (error) {}
});
