import { createSlice } from '@reduxjs/toolkit';
import { addNextKin, deleteNextKin, getNextKin, getNextKins, getNextKinsNotPaginated, updateNextKin } from './nextKinActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    NextKinsLoading: false,
    NextKinSuccess: false,
    NextKinMsg: '',
    NextKins: [],
    NextKin: null
};

const NextKin = createSlice({
    name: 'NextKin',
    initialState,
    reducers: {
        clearNextKinMessages: (state) => {
            state.error = false;
            state.NextKinMsg = '';
            state.NextKinSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNextKins.pending, (state) => {
            state.NextKinsLoading = true;
        });
        builder.addCase(getNextKins.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.NextKins = action.payload?.data;
            state.error = action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
            state.NextKinSuccess = !action.payload?.error;
        });
        builder.addCase(getNextKin.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.NextKin = action.payload?.data[0];
            state.error = action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
            state.NextKinSuccess = !action.payload?.error;
        });
        builder.addCase(getNextKinsNotPaginated.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.NextKinSuccess = !action.payload?.error;
            state.NextKins = action.payload?.data;
            state.NextKin = action.payload?.data[0];
            state.error = action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
        });
        builder.addCase(addNextKin.pending, (state) => {
            state.NextKinsLoading = true;
        });
        builder.addCase(addNextKin.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.error = action.payload?.error;
            state.NextKinSuccess = !action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
        });
        builder.addCase(updateNextKin.pending, (state) => {
            state.NextKinsLoading = true;
        });
        builder.addCase(updateNextKin.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.error = action.payload?.error;
            state.NextKinSuccess = !action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
        });
        builder.addCase(deleteNextKin.fulfilled, (state, action) => {
            state.NextKinsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.NextKins = action.payload?.data.items;
            state.error = action.payload?.error;
            state.NextKinMsg = action.payload?.msg;
            state.NextKinSuccess = !action.payload?.error;
        });
    }
});

export const { clearNextKinMessages } = NextKin.actions;

export default NextKin.reducer;
