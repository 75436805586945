import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/api';

export const getPropertyTypes = createAsyncThunk('PropertyType/getPaginatedPropertyType', async ({ page, name, from, to }) => {
    try {
        let url = '';
        if (name) {
            url = `/config/getPropertyType?name=${name}&page=${page}`;
        } else if (name && from) {
            url = `/config/getPropertyType?name=${name}&from=${from}&page=${page}`;
        } else if (name && to) {
            url = `/config/getPropertyType?name=${name}&to=${to}&page=${page}`;
        } else if (name && from && to) {
            url = `/config/getPropertyType?admin_area=${name}&from=${from}&to=${to}&page=${page}`;
        } else {
            url = `/config/getPropertyType?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated
export const getPropertyTypesNotPaginated = createAsyncThunk('PropertyType/getPropertyType', async () => {
    try {
        const { data } = await api.get(`/config/getPropertyType`);
        return data;
    } catch (error) {}
});
export const getPropertyType = createAsyncThunk('PropertyType/getSinglePropertyType', async ({ id }) => {
    try {
        const { data } = await api.get(`/config/getPropertyType?id=${id}`);
        return data;
    } catch (error) {}
});

export const deletePropertyTypes = createAsyncThunk('PropertyType/deletePropertyType', async ({ page, id }) => {
    try {
        await api.delete(`/config/deletePropertyType/${id}`);
        const { data } = await api.get(`/config/getPropertyType?page=${page}`);
        return data;
    } catch (error) {}
});

export const addPropertyTypes = createAsyncThunk('PropertyType/addPropertyType', async ({ name, description }) => {
    try {
        const { data } = await api.post(`/config/addPropertyType`, {
            description,
            name
        });
        return data;
    } catch (error) {}
});

export const updatePropertyTypes = createAsyncThunk('PropertyType/updatePropertyType', async ({ name, description, id }) => {
    try {
        const { data } = await api.patch(`/config/editPropertyType/${id}`, {
            description,
            name,
            id
        });
        return data;
    } catch (error) {}
});
