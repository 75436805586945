import { createSlice } from '@reduxjs/toolkit';
import {
    addLandlordShieldPlus,
    getLandlordShieldPlus,
    deleteLandlordShieldPlus,
    getLandlordShieldPluses,
    getLandlordShieldPlusNotPaginated,
    updateLandlordShieldPlus
} from './landlordShieldPlusActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    LandlordShieldPlusLoading: false,
    LandlordShieldPlusesuccess: false,
    LandlordShieldPlusMsg: '',
    LandlordShieldPluses: [],
    LandlordShieldPlus: undefined
};

const LandlordShieldPlus = createSlice({
    name: 'LandlordShieldPlus',
    initialState,
    reducers: {
        clearLandlordShieldPlusMessages: (state) => {
            state.error = false;
            state.LandlordShieldPlusMsg = '';
            state.LandlordShieldPlusesuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLandlordShieldPluses.pending, (state) => {
            state.LandlordShieldPlusLoading = true;
        });
        builder.addCase(getLandlordShieldPluses.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.LandlordShieldPluses = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
        });
        builder.addCase(getLandlordShieldPlus.pending, (state) => {
            state.LandlordShieldPlusLoading = true;
        });
        builder.addCase(getLandlordShieldPlus.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.LandlordShieldPlus = action.payload?.data[0];
            state.error = action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
        });
        builder.addCase(getLandlordShieldPlusNotPaginated.pending, (state) => {
            state.LandlordShieldPlusLoading = true;
        });
        builder.addCase(getLandlordShieldPlusNotPaginated.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
            state.LandlordShieldPluses = action.payload?.data;
            state.error = action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(addLandlordShieldPlus.pending, (state) => {
            state.LandlordShieldPlusLoading = true;
        });
        builder.addCase(addLandlordShieldPlus.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.error = action.payload?.error;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(updateLandlordShieldPlus.pending, (state) => {
            state.LandlordShieldPlusLoading = true;
        });
        builder.addCase(updateLandlordShieldPlus.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.error = action.payload?.error;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
        });
        builder.addCase(deleteLandlordShieldPlus.fulfilled, (state, action) => {
            state.LandlordShieldPlusLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.LandlordShieldPluses = action.payload?.data.items;
            state.error = action.payload?.error;
            state.LandlordShieldPlusMsg = action.payload?.msg;
            state.LandlordShieldPlusesuccess = !action.payload?.error;
        });
    }
});

export const { clearLandlordShieldPlusMessages } = LandlordShieldPlus.actions;

export default LandlordShieldPlus.reducer;
